import {
  GET_PROJECT_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_TYPE_LIST, SET_PROJECT_LOADING, GET_PROJECT, GET_STANDARD_LIST, GET_COBENEFIT_LIST, GET_SDG_LIST, SET_PROJECT_QUERY_PARAMS
} from "./actionType";

// common success
export const ProjectApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const ProjectApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setProjectApiLoading = (value) => ({
  type: SET_PROJECT_LOADING,
  payload: { value },
});

export const setProjectQueryParams = (params) => ({
  type: SET_PROJECT_QUERY_PARAMS,
  payload: params,
});

export const getProjectList = (params) => ({
  type: GET_PROJECT_LIST,
  payload: params,
});

export const getProject = (params) => ({
  type: GET_PROJECT,
  payload: params,
});

export const updateProject = (project) => ({
  type: UPDATE_PROJECT,
  payload: project,
});

export const updateProjectSuccess = (project) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: project,
});

export const updateProjectFail = (error) => ({
  type: UPDATE_PROJECT_FAIL,
  payload: error,
});

export const addNewProject = (project) => ({
  type: ADD_NEW_PROJECT,
  payload: project,
});

export const addProjectSuccess = (project) => ({
  type: ADD_PROJECT_SUCCESS,
  payload: project,
});

export const addProjectFail = (error) => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
});
export const deleteProject = (project) => ({
  type: DELETE_PROJECT,
  payload: project,
});

export const deleteProjectSuccess = (project) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
});

export const deleteProjectFail = (error) => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
});

export const getTypeList = (params) => ({
  type: GET_TYPE_LIST,
  payload: params,
});

export const getStandardList = (params) => {
  return {
    type: GET_STANDARD_LIST,
    payload: params,
  };
};

export const getCoBenefitList = (params) => {
  return {
    type: GET_COBENEFIT_LIST,
    payload: params,
  };
};

export const getSDGList = (params) => {
  return {
    type: GET_SDG_LIST,
    payload: params,
  };
};


