import React, { useEffect, useState, useRef, useMemo } from 'react';
import Select from 'react-select';
import { multiply, validate } from 'js-big-decimal';
import { debounce } from 'lodash';
//redux
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Label,
  Input,
  Button,
  ModalHeader,
  FormFeedback,
  Form,
} from 'reactstrap';

import Cleave from 'cleave.js/react';

import { 
  addNewTrade,
  updateTrade,
  getProjectList,
  getBuyerList,
  getCountryList,
  getTypeList,
  getStandardList,
  getCoBenefitList,
  getSDGList, 
} from '../../../store/actions';

// Formik
import * as Yup from 'yup';
import { Field, Formik, useFormik } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { api } from '../../../config';
import axios from 'axios';

import { URL } from '../../../constants';
import { toast } from 'react-toastify';
import { select } from 'redux-saga/effects';

const MarketClassificationOptions = [
  { label: 'Voluntary Carbon Market', value: 'Voluntary Carbon Market' },
  { label: 'Alberta Emissions Offset System', value: 'Alberta Emissions Offset System' },
  { label: 'Alberta CCIR', value: 'Alberta CCIR' },
  { label: 'Australia Emissions Reduction Fund', value: 'Australia Emissions Reduction Fund' },
  { label: 'Beijing Forestry Offset Mechanism', value: 'Beijing Forestry Offset Mechanism' },
  { label: 'Beijing Parking Offset Crediting Mechanism', value: 'Beijing Parking Offset Crediting Mechanism' },
  { label: 'British Columbia Offset Program', value: 'British Columbia Offset Program' },
  { label: 'California CaT', value: 'California CaT' },
  { label: 'California Compliance Offset Program', value: 'California Compliance Offset Program' },
  { label: 'Clean Development Mechanism', value: 'Clean Development Mechanism' },
  { label: 'China GHG Voluntary Emission Reduction Program', value: 'China GHG Voluntary Emission Reduction Program' },
  { label: 'Chongqing Crediting Mechanism', value: 'Chongqing Crediting Mechanism' },
  { label: 'Colombia carbon tax', value: 'Colombia carbon tax' },
  { label: 'CORSIA', value: 'CORSIA' },
  { label: 'EU ETS', value: 'EU ETS' },
  { label: 'Fujian Forestry Offset Crediting Mechanism', value: 'Fujian Forestry Offset Crediting Mechanism' },
  { label: 'Guangdong Pu Hui Offset Crediting Mechanism', value: 'Guangdong Pu Hui Offset Crediting Mechanism' },
  { label: 'Indo-Pacific Carbon Offset Scheme', value: 'Indo-Pacific Carbon Offset Scheme' },
  { label: 'J-Credit Scheme', value: 'J-Credit Scheme' },
  { label: 'Joint Crediting Mechanism', value: 'Joint Crediting Mechanism' },
  { label: 'Kazakhstan Crediting Mechanism', value: 'Kazakhstan Crediting Mechanism' },
  { label: 'NZ ETS', value: 'NZ ETS' },
  { label: 'Québec Offset Crediting Mechanism', value: 'Québec Offset Crediting Mechanism' },
  { label: 'Republic of Korea Offset Credit Mechanism', value: 'Republic of Korea Offset Credit Mechanism' },
  { label: 'RGGI CO2 Offset Mechanism', value: 'RGGI CO2 Offset Mechanism' },
  { label: 'Saitama Forest Absorption Certification System', value: 'Saitama Forest Absorption Certification System' },
  { label: 'Saitama Target Setting Emissions Trading System', value: 'Saitama Target Setting Emissions Trading System' },
  { label: 'Saskatchewan GHG Offset Program', value: 'Saskatchewan GHG Offset Program' },
  { label: 'South African Carbon Tax', value: 'South African Carbon Tax' },
  { label: 'Spain FES-CO2 program', value: 'Spain FES-CO2 program' },
  { label: 'Spanish Carbon Fund for a Sustainable Economy', value: 'Spanish Carbon Fund for a Sustainable Economy' },
  { label: 'Switzerland CO2 Attestations Crediting Mechanism', value: 'Switzerland CO2 Attestations Crediting Mechanism' },
  { label: 'Taiwan GHG Offset Management Program', value: 'Taiwan GHG Offset Management Program' },
  { label: 'Thailand Voluntary Emission Reduction Program', value: 'Thailand Voluntary Emission Reduction Program' },
  { label: 'Tokyo Cap-and-Trade Program', value: 'Tokyo Cap-and-Trade Program' },
  { label: 'Compliance - Unspecified or Other', value: 'Compliance - Unspecified or Other' },
  { label: 'Confidential', value: 'Confidential' },

];

const EndUserOfIntermediaryOptions = [
  { label: 'End User', value: 'End User' },
  {
    label: 'Intermediary - does NOT take ownership',
    value: 'Intermediary - does NOT take ownership',
  },
  {
    label: 'Intermediary - takes ownership',
    value: 'Intermediary - takes ownership',
  },
  {
    label: 'Intermediary - unknown ownership',
    value: 'Intermediary - unknown ownership',
  },
  { label: 'Both', value: 'Both' },
  { label: 'Unknown', value: 'Unknown' },
];

const OffsetGoalsOptions = [
  { label: 'Net zero', value: 'Net zero' },
  { label: 'Voluntary net zero', value: 'Voluntary net zero' },
  { label: 'Carbon neutral', value: 'Carbon neutral' },
  {
    label: 'Voluntary carbon neutral goal',
    value: 'Voluntary carbon neutral goal',
  },
  { label: 'Voluntary goal unknown', value: 'Voluntary goal unknown' },
  {
    label: 'Climate neutral agricultural commodity',
    value: 'Climate neutral agricultural commodity',
  },
  { label: 'Not applicable', value: 'Not applicable' },
  { label: 'Other', value: 'Other' },
];

const ReasonNOIDOptions = [
  { label: 'Project ID was Unassigned at Transaction Date', value: 'Project ID was Unassigned at Transaction Date', futureDate: true },
  { label: 'Project ID is Confidential per Buyer Contract', value: 'Project ID is Confidential per Buyer Contract' },
  { label: 'Project ID is Unknown', value: 'Project ID is Unknown', futureDate: true },
  {
    label: 'Project ID does not Yet Exist, Project is Pending Registration',
    value: 'Project ID does not Yet Exist, Project is Pending Registration',
    futureDate: true
  },
  { label: 'Project ID does not Exist, Carbon Standard does not have a registry', 
    value: 'Project ID does not Exist, Carbon Standard does not have a registry' },
  {
    label: 'Project ID does not Exist, Project follows an Internal Methodology',
    value: 'Project ID does not Exist, Project follows an Internal Methodology',
  },
  { label: 'Other', value: 'Other' },
];

const AdditionalCertifications = [
  { label: 'American Tree Farm System', value: 'American Tree Farm System' },
  { label: 'Clean Development Mechanism (CDM)', value: 'Clean Development Mechanism (CDM)'},
  { label: 'Fairtrade Climate Standard', value: 'Fairtrade Climate Standard'},
  { label: 'Fairtrade (excluding Climate Standard)', value: 'Fairtrade (excluding Climate Standard)'},
  { label: 'Forest Stewardship Council', value: 'Forest Stewardship Council'},
  { label: 'Green-e Climate', value: 'Green-e Climate' },
  { label: 'iREC', value: 'iREC' },
  { label: 'ISAE 3000 by SGS', value: 'ISAE 3000 by SGS' },
  { label: 'ISO14064:2', value: 'ISO14064:2'},
  { label: 'ISO9001, ISO 14001, OHSAS18001', value: 'ISO9001, ISO 14001, OHSAS18001'},
  { label: 'Organic', value: 'Organic'},
  { label: 'Other', value: 'Other'},
  { label: 'Programme for the Endorsement of Forest Certification (PEFC)', value: 'Programme for the Endorsement of Forest Certification (PEFC)'},
  { label: 'Rainforest Alliance', value: 'Rainforest Alliance'},
  { label: 'SD Vista', value: 'SD Vista'},
  { label: 'Sustainable Forestry Initiative', value: 'Sustainable Forestry Initiative'},
  { label: 'UK Woodland Assurance Standard', value: 'UK Woodland Assurance Standard'},
];

const TradeTypes = [
  {label: 'Credit transaction', value: 'Credit transaction' },
  {label: 'Offtake Agreement in Project Financing', value: 'Offtake Agreement in Project Financing' },
  {label: 'Embedded in a product/good/service', value: 'Embedded in a product/good/service' },
]

const TransferOrRetireOptions = [
  {label: 'Transfer credits to buyer registry account', value: 'Transfer credits to buyer registry account' },
  {label: 'Retire credits on behalf of buyer', value: 'Retire credits on behalf of buyer' },
]

const CreditIssuanceOptions = [
  {label: 'Pre-/pending/projected issuance credits', value: 'Pre-/pending/projected issuance credits' },
  {label: 'Verified/issued credits', value: 'Verified/issued credits' },
]

const monthOptions = [
  {label: 'Year only', value: 0 },
  {label: '1', value: 1 },
  {label: '2', value: 2 },
  {label: '3', value: 3 },
  {label: '4', value: 4 },
  {label: '5', value: 5 },
  {label: '6', value: 6 },
  {label: '7', value: 7 },
  {label: '8', value: 8 },
  {label: '9', value: 9 },
  {label: '10', value: 10 },
  {label: '11', value: 11 },
  {label: '12', value: 12 },
]

let yearOptions = [];
const currentYear = new Date().getFullYear();
for (let i = currentYear; i >= 2000; i--) {
  yearOptions.push({ label: i, value: i });
}
let quarterOptions = [
  {label: '1', value: 1 },
  {label: '2', value: 2 },
  {label: '3', value: 3 },
  {label: '4', value: 4 },
];
let semesterOptions = [
  {label: '1', value: 1 },
  {label: '2', value: 2 },
];




const clearNumber = (number) => {
  if (typeof number === 'number') {
    return number;
  } else return number && Number(number?.replaceAll(',', ''));
};

const getFormFieldArray = (formValue) => {
  if( formValue === '' || formValue == undefined ){
    return [];
  } else {
    return formValue;
  }
};

const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
}

const TradeForm = ({
  isEdit,
  currentTrade,
  modal,
  setModal,
  toggle,
  onClosed,
  setCreateUserModal,
  newBuyer
}) => {
  const dispatch = useDispatch();
  const [projectSearch, setProjectSearch] = useState('');

  const { user, Buyer, countries, typeList, standardList, coBenefitList, sdgList, standards } = useSelector((state) => ({
    user: state.Login.user,
    Buyer: state.Buyer,
    countries: state.Login.countries.map((el) => ({ value: el.id, label: el.name })) || [],
    typeList:
      state.Project.typeList.map((el) => ({
        value: el.typename,
        label: el.typename,
      })).filter(onlyUnique) || [],
    standardList:
      state.Project.standardList
        .map((el) => ({ value: el.standardid, label: el.name }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)) || [],
    coBenefitList:
      state.Project.coBenefitList
        .map((el) => ({ value: el.cobenefitid, label: el.cobenefitname }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)) || [],
    sdgList:
      state.Project.sdgList
        .map((el) => ({ value: el.sdgcode, label: el.sdgcode+'. '+el.sdgname }))
        .sort((a, b) => (a.value > b.value ? 1 : -1)) || [],
    standards: state.Project.standardList,
  }));


  // const { projectLoading, isTradeCreated, isTradeDelete, isTradeSuccess, error } = useSelector(
  //   (state) => ({
  //     isTradeCreated: state.Trade.isTradeCreated,
  //     isTradeSuccess: state.Trade.isTradeSuccess,
  //     isTradeDelete: state.Trade.isTradeDelete,
  //     error: state.Trade.error,
  //     projectLoading: state.Project.projectLoading,
  //   })
  // ); 


  const [buyerLoading, setBuyerLoading] = useState(false);
  // const [projects, setProjects] = useState([]);

  // const [buyers, setBuyers] = useState([]);
  // const [buyerList, setBuyerList] = useState(Buyer.buyerList);
  // const [selectBuyerList, setSelectBuyerList] = useState([]);
  // const [projectList, setProjectList] = useState();
  // const [justCall, setJustCall] = useState();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [tradeDate, setTradeDate] = useState('');
  const [tradeYear, setTradeYear] = useState(null);
  const [tradeMonth, setTradeMonth] = useState(null);
  const [tradeQuarter, setTradeQuarter] = useState(null);
  const [tradeSemester, setTradeSemester] = useState(null);
  const [hasFullDate, setHasFullDate] = useState(1);
  const [currencyRateList, setCurrencyRateList] = useState();
  const [projectidVisible, setProjectidVisible] = useState();
  const [currentlySelectedProject, setCurrentlySelectedProject] = useState( isEdit?.projectid ? isEdit.projectid.project_code : '' );
  const [selectableProjectsList, setSelectableProjectsList] = useState([]);
  const [showSelectableProjectsList, setShowSelectableProjectsList] = useState(false);
  

  const getCurrency = () => {
    axios
      .get(URL.get_currency)
      .then((r) =>
        setCurrencyOptions(
          r.map((el) => ({
            label: `${el.code + '-' + el.name}`,
            value: `${el.code}`,
          }))
        )
      )
      .catch((e) => console.log(e));
  };

  
  const USDRates = (tradeDate, tradeYear, tradeMonth, tradeQuarter, tradeSemester) => {
    axios.get(URL.get_currency_conversion_values+'?trade_date='+tradeDate+'&trade_year='+tradeYear+'&trade_month='+tradeMonth+'&trade_quarter='+tradeQuarter+'&trade_semester='+tradeSemester+'&has_full_date='+hasFullDate)
    .then((response) => {
      setCurrencyRateList(response.data);
    })
  };

  const getCurrencyRates = (value) => {
    if( value ){
      let result;
      for (const key in currencyRateList) {
        if (key === value) {
          result = currencyRateList[key];
        }
      }
      if( result ){
        return result;
      }
    }
    return undefined
  };

  const buyerListIncludesNewBuyer = () => {
    if( Buyer.buyerList && newBuyer ){
      for (const key in Buyer.buyerList) {
        if (Buyer.buyerList[key].buyer_id == newBuyer.buyer_id) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  useEffect(() => {
    getCurrency();
  }, [tradeDate, currentTrade?.date_transaction]);



  useEffect(() => {
    if( newBuyer ){
      if( ! buyerListIncludesNewBuyer() ){
        Buyer.buyerList.push( { buyer_id : newBuyer.buyer_id, buyer_name: newBuyer.buyer_name } ); 
      }
      validation.setFieldValue('buyer', newBuyer.buyer_id);
      newBuyer = null;
    }
  }, [Buyer.buyerList]);

  useEffect(() => {
    let data = tradeDate || currentTrade?.date_transaction;
    let year = tradeYear || currentTrade?.trade_year || 0;
    let month = tradeMonth || currentTrade?.trade_month || 0;
    let quarter = tradeQuarter || currentTrade?.trade_quarter || 0;
    let semester = tradeSemester || currentTrade?.trade_semester || 0;
    data = moment.utc(data).format('yyyy-MM-DD');
    if (data || year) USDRates(data, year, month, quarter, semester);
  }, [tradeDate, currentTrade?.date_transaction, tradeYear, currentTrade?.trade_year, tradeMonth, currentTrade?.trade_month, currentTrade?.trade_quarter, tradeQuarter, currentTrade?.trade_semester, tradeSemester]);


  const TradeSchema = Yup.object({
    volume_tco2e: Yup.number().required('Please enter the trade volume.').positive('The trade volume must be a positive number').typeError('Sorry, this must be a number.'),
    volume_w_price: Yup.string(),
    value_usd: Yup.string(),
    conversion: Yup.number(),
    price_local: Yup.number().required('Please enter the price per tonne.').positive('Please enter the price per tonne.').typeError('Sorry, this must be a number.'),
    price_usd_tcoe: Yup.string(),
    date_transaction: Yup.string().nullable().test('date', 'Please choose a date, or indicate that no specific date is available and provide general date information.', (value, validation) => validateDateTransaction(value, validation)),
    no_date_transaction: Yup.string(),
    trade_year: Yup.string().nullable().test('year', 'Please select a year.', (value, validation) => validateTradeYear(value, validation)),
    trade_month: Yup.string().nullable(),
    trade_quarter: Yup.string().nullable(),
    trade_semester: Yup.string().nullable(),
    vintage_year: Yup.string().matches(/^\d\d\d\d[-]\d\d\d\d$|^\d\d\d\d$/, 'Please enter a four digit year, or a range of years separated by a hyphen (YYYY or YYYY-YYYY)'),
    market_classification: Yup.string().required('Please select a market classification.'),
    forwardcontract_options: Yup.string(),
    forwardcontract_maturitydate: Yup.string().nullable(),
    end_user_or_intermediary: Yup.string(),
    name_of_end_user: Yup.string(),
    buyeroffsetgoals: Yup.string(),
    buyerdepartment: Yup.string(),
    scopes: Yup.string(),
    scope_1: Yup.string(),
    scope_2: Yup.string(),
    scope_3: Yup.string(),
    scope3_activity: Yup.string(),
    buyer_internal_or_resell: Yup.string(),
    buyer_qty_compliance: Yup.string(),
    buyer_retire_or_store: Yup.string(),
    buyer_retirement_policy: Yup.string(),
    buyer_vintage_cutoff: Yup.string(),
    buyer_consumerproduct: Yup.string(),
    development_usdt: Yup.string(),
    projectfinance_details: Yup.string(),
    projectfinance_usedcredithistory: Yup.string(),
    buyerjurisdiction: Yup.string(),
    project_jurisdictional_redd: Yup.string(),
    currency: Yup.string().required('Please select a currency.'),
    projid_known: Yup.string(),
    market_isspot: Yup.string(),
    market_isprimary: Yup.string(),
    market_isforward: Yup.string(),
    market_issecondary: Yup.string(),
    projecdtID: Yup.number(),
    project_noid_reason: Yup.string(),
    project_noid_reason_other: Yup.string(),
    project_noid_date_register: Yup.string().nullable(),
    project_name: Yup.string(),
    project_country_id: Yup.string(),
    project_type: Yup.string(),
    project_3p_carbon_standard: Yup.string(),
    project_3p_carbon_standard_id: Yup.number(),
    project_methodology: Yup.string(),
    project_cobenefits: Yup.array(),
    project_sdgs: Yup.array(),
    project_additional_certification: Yup.string(),
    trade_type: Yup.string(),
    transfer_or_retire: Yup.string(),
    credit_issuance_status: Yup.string(),
    estimated_or_actual_delivery_date:Yup.string().nullable(),
    
  });



  const getValueUSD = ( currency, volume, pricePerTonne ) => {
    let valueUSD = Number(clearNumber(volume)) * Number(pricePerTonne);
    let conversionRate = getCurrencyRates(currency);
    if( conversionRate !== undefined && conversionRate != 0 ){
      valueUSD = Math.round(valueUSD / conversionRate * 100) / 100;
    } else {
      valueUSD = 0;
    }
    return valueUSD;
  }

  const getPriceUSD = (currency, price_local) => {
    let priceUSD = price_local;
    let conversionRate = getCurrencyRates(currency);
    if( conversionRate !== undefined && conversionRate != 0 ){
      priceUSD = priceUSD / conversionRate;
    } else {
      priceUSD = 0;
    }
    return priceUSD;
  }
  

  const returnDateOrNull = ( chosenDate ) => {
    if( chosenDate ){
      return new Date(chosenDate);
    } else {
      return null;
    }
  }

  const getDateTransaction = (date_transaction, trade_year, trade_month, trade_quarter, trade_semester, no_date_transaction) => {
    let dateTransaction = date_transaction;
    if( no_date_transaction && trade_year && trade_month ){
      trade_month = trade_month.toString().padStart(2, '0')
      dateTransaction = `${trade_month}/01/${trade_year}`;
    } else if( no_date_transaction && trade_year && trade_quarter ){
      trade_month = '01';
      if( trade_quarter == 2 ){
        trade_month = '04';
      } else if( trade_quarter == 3 ){
        trade_month = '07';
      } else if( trade_quarter == 4 ){
        trade_month = '10';
      }
      dateTransaction = `${trade_month}/${trade_month}/${trade_year}`;
    } else if( no_date_transaction && trade_year && trade_semester ){
      trade_month = '01';
      if( trade_semester == 2 ){
        trade_month = '07';
      } 
      dateTransaction = `${trade_month}/${trade_month}/${trade_year}`;
    } else if( no_date_transaction && trade_year ){
      dateTransaction = `01/01/${trade_year}`;
    }
    return new Date(dateTransaction)
  }

  const getStandardName = (standard_id) => {
    let standardName = '';
    for (const key in standards) {
      if (standards[key].standardid == standard_id) {
        standardName = standards[key].name;
      }
    }
    return standardName;
  }

  const isFourDigitNum = (num) => {
    return /^\d{4}$/.test(num);
  }
  
  const getVintageStart = (vintage_year) => {
    let vintageStart = null;
    if( isFourDigitNum(vintage_year) ){
      vintageStart = parseInt(vintage_year);
    } else if( vintage_year.split('-').length == 2 ){
      if( isFourDigitNum(vintage_year.split('-')[0]) ){
        vintageStart = parseInt(vintage_year.split('-')[0]);
      }
    }
    return vintageStart;
  }

  const getVintageEnd = (vintage_year) => {
    let vintageEnd = null;
    if( isFourDigitNum(vintage_year) ){
      vintageEnd = parseInt(vintage_year);
    } else if( vintage_year.split('-').length == 2 ){
      if( isFourDigitNum(vintage_year.split('-')[1]) ){
        vintageEnd = parseInt(vintage_year.split('-')[1]);
      }
    }
    return vintageEnd;
  }

  


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      projectid: currentTrade?.projectID || null,
      buyer: currentTrade?.buyer || "",
      volume_tco2e: currentTrade?.volume_tco2e || 0,
      volume_w_price: currentTrade?.volume_w_price || 0,
      value_usd: currentTrade?.value_usd || 0,
      conversion: currentTrade?.conversion || 1,
      price_local: currentTrade?.price_local || 0,
      date_transaction: currentTrade?.date_transaction || null,
      trade_year: currentTrade?.trade_year || null,
      trade_month: currentTrade?.trade_month || null,
      trade_quarter: currentTrade?.trade_quarter || null,
      trade_semester: currentTrade?.trade_semester || null,
      no_date_transaction: currentTrade?.no_date_transaction || '',
      vintage_year: currentTrade?.vintage_year || '',
      market_classification: currentTrade?.market_classification || '',
      forwardcontract_options: currentTrade?.forwardcontract_options || '',
      forwardcontract_maturitydate: currentTrade?.forwardcontract_maturitydate || null,
      end_user_or_intermediary: currentTrade?.end_user_or_intermediary || '',
      name_of_end_user: currentTrade?.name_of_end_user || '',
      buyeroffsetgoals: currentTrade?.buyeroffsetgoals || '',
      buyerdepartment: currentTrade?.buyerdepartment || '',
      scopes: currentTrade?.scopes || '',
      scope_1: currentTrade?.scope_1 || 0,
      scope_2: currentTrade?.scope_2 || 0,
      scope_3: currentTrade?.scope_3 || 0,
      scope3_activity: currentTrade?.scope3_activity || '',
      buyer_internal_or_resell: currentTrade?.buyer_internal_or_resell || '',
      buyer_qty_compliance: currentTrade?.buyer_qty_compliance || '',
      buyer_retire_or_store: currentTrade?.buyer_retire_or_store || '',
      buyer_retirement_policy: currentTrade?.buyer_retirement_policy || '',
      buyer_vintage_cutoff: currentTrade?.buyer_vintage_cutoff || '',
      buyer_consumerproduct: currentTrade?.buyer_consumerproduct || '',
      development_usdt: currentTrade?.development_usdt || '',
      projectfinance_details: currentTrade?.projectfinance_details || '',
      projectfinance_usedcredithistory: currentTrade?.projectfinance_usedcredithistory || '',
      buyerjurisdiction: currentTrade?.buyerjurisdiction || '',
      project_jurisdictional_redd: currentTrade?.project_jurisdictional_redd || '',
      currency: currentTrade?.currency || '',
      projid_known: currentTrade?.projid_known || 0,
      market_isspot: currentTrade?.market_isspot || '',
      market_isprimary: currentTrade?.market_isprimary || '',
      market_isforward: currentTrade?.market_isforward || '',
      market_issecondary: currentTrade?.market_issecondary || '',
      project_noid_reason: currentTrade?.project_noid_reason || '',
      project_noid_reason_other: currentTrade?.project_noid_reason_other || '',
      project_noid_date_register: currentTrade?.project_noid_date_register || null,
      project_name: currentTrade?.project_name || '',
      project_country_id: currentTrade?.project_country || '',
      project_3p_carbon_standard: currentTrade?.project_3p_carbon_standard || '',
      project_3p_carbon_standard_id: currentTrade?.project_3p_carbon_standard_id || 0,
      project_type: currentTrade?.project_type || '',
      project_methodology: currentTrade?.project_methodology || '',
      project_cobenefits: !currentTrade?.project_cobenefits ? [] : currentTrade?.project_cobenefits.split(','),
      project_sdgs: !currentTrade?.project_sdgs ? [] : currentTrade?.project_sdgs.split(','),
      project_additional_certification: currentTrade?.project_additional_certification || '',
      trade_type: currentTrade?.trade_type || '',
      transfer_or_retire: currentTrade?.transfer_or_retire || '',
      credit_issuance_status: currentTrade?.credit_issuance_status || '',
      estimated_or_actual_delivery_date: currentTrade?.estimated_or_actual_delivery_date || null,
    },

    validationSchema: TradeSchema,
    onSubmit: (values) => {
      if (isEdit) {
        const updatedTrade = {
          trade_id: currentTrade ? currentTrade.trade_id : 0,
          respondent: values.respondent,
          buyer: values.buyer,
          projectid: values.projectID,
          volume_tco2e: clearNumber(values.volume_tco2e),
          volume_w_price: values.volume_w_price,
          value_usd: getValueUSD(values.currency, values.volume_tco2e, values.price_local),
          conversion: getCurrencyRates(values.currency)?.toString(),
          price_local: values.price_local,
          price_usd_tcoe: getPriceUSD(values.currency, values.price_local),
          date_transaction: getDateTransaction(values.date_transaction, values.trade_year, values.trade_month, values.trade_quarter, values.trade_semester, values.no_date_transaction),
          trade_year: values.trade_year,
          trade_month: values.trade_month,
          trade_quarter: values.trade_quarter,
          trade_semester: values.trade_semester,
          no_date_transaction: values.no_date_transaction,
          vintage_year: values.vintage_year,
          vintage_start: getVintageStart(values.vintage_year),
          vintage_end: getVintageEnd(values.vintage_year),
          market_classification: values.market_classification,
          forwardcontract_options: values.forwardcontract_options,
          forwardcontract_maturitydate: returnDateOrNull(values.forwardcontract_maturitydate),
          end_user_or_intermediary: values.end_user_or_intermediary,
          name_of_end_user: values.name_of_end_user,
          buyeroffsetgoals: values.buyeroffsetgoals,
          buyerdepartment: values.buyerdepartment,
          scopes: null,
          scope_1: clearNumber(values.scope_1),
          scope_2: clearNumber(values.scope_2),
          scope_3: clearNumber(values.scope_3),
          scope3_activity: values.scope3_activity,
          buyer_internal_or_resell: values.buyer_internal_or_resell,
          buyer_qty_compliance: values.buyer_qty_compliance,
          buyer_retire_or_store: values.buyer_retire_or_store,
          // buyer_retirement_policy: values.buyer_retirement_policy,
          buyer_retirement_policy: null,
          buyer_vintage_cutoff: values.buyer_vintage_cutoff,
          buyer_consumerproduct: values.buyer_consumerproduct,
          development_usdt: values.development_usdt,
          projectfinance_details: values.projectfinance_details,
          projectfinance_usedcredithistory: values.projectfinance_usedcredithistory,
          buyerjurisdiction: values.buyerjurisdiction,
          project_jurisdictional_redd: values.project_jurisdictional_redd,
          currency: values.currency,
          projid_known: values.projid_known,
          market_isspot: values.market_isspot,
          market_isprimary: values.market_isprimary,
          market_isforward: values.market_isforward,
          market_issecondary: values.market_issecondary,
          project_noid_reason: values.project_noid_reason,
          project_noid_reason_other: values.project_noid_reason_other,
          project_noid_date_register: returnDateOrNull(values.project_noid_date_register),
          project_name: values.project_name,
          project_country: values.project_country_id,
          project_type: values.project_type,
          project_3p_carbon_standard: values.project_3p_carbon_standard_id == 0 ? null : getStandardName(values.project_3p_carbon_standard_id),
          project_3p_carbon_standard_id: values.project_3p_carbon_standard_id == 0 ? null : values.project_3p_carbon_standard_id,
          project_methodology: values.project_methodology,
          project_cobenefits: values.project_cobenefits.length == 0 ? '' : values.project_cobenefits.join(','),
          project_cobenefits_rel: getFormFieldArray(values.project_cobenefits),
          project_sdgs: values.project_sdgs.length == 0 ? '' : values.project_sdgs.join(','),
          project_sdgs_rel: getFormFieldArray(values.project_sdgs),
          project_additional_certification: values.project_additional_certification,
          trade_type: values.trade_type,
          transfer_or_retire: values.transfer_or_retire,
          credit_issuance_status: values.credit_issuance_status,
          estimated_or_actual_delivery_date: returnDateOrNull(values.estimated_or_actual_delivery_date),
        };
        // update customer
        dispatch(updateTrade({ data: updatedTrade, callback: toggle }));
        validation.resetForm();
      } else {
        const newTrade = {
          respondent: user.respondent_id,
          buyer: values['buyer'],
          projectid: values['projectID'], 
          volume_tco2e: clearNumber(values['volume_tco2e']),
          volume_w_price: values['volume_w_price'],
          value_usd: getValueUSD(values['currency'], values['volume_tco2e'], values['price_local']),
          conversion: getCurrencyRates(values['currency'])?.toString(), 
          price_local: values['price_local'],
          price_usd_tcoe: getPriceUSD(values['currency'], values['price_local']),
          date_transaction: getDateTransaction(values['date_transaction'], values['trade_year'], values['trade_month'], values['trade_quarter'], values['trade_semester'], values['no_date_transaction']),
          trade_year: values['trade_year'],
          trade_month: values['trade_month'],
          trade_quarter: values['trade_quarter'],
          trade_semester: values['trade_semester'],
          no_date_transaction: values['no_date_transaction'],
          vintage_year: values['vintage_year'],
          vintage_start: getVintageStart(values['vintage_year']),
          vintage_end: getVintageEnd(values['vintage_year']),
          market_classification: values['market_classification'],
          forwardcontract_options: values['forwardcontract_options'],
          forwardcontract_maturitydate: returnDateOrNull(values['forwardcontract_maturitydate']),
          end_user_or_intermediary: values['end_user_or_intermediary'],
          name_of_end_user: values['name_of_end_user'],
          buyeroffsetgoals: values['buyeroffsetgoals'],
          buyerdepartment: values['buyerdepartment'],
          // scopes: values["scopes"],
          scopes: null,
          scope_1: clearNumber(values['scope_1']),
          scope_2: clearNumber(values['scope_2']),
          scope_3: clearNumber(values['scope_3']),
          scope3_activity: values['scope3_activity'],
          buyer_internal_or_resell: values['buyer_internal_or_resell'],
          buyer_qty_compliance: clearNumber(values['buyer_qty_compliance']),
          buyer_retire_or_store: values['buyer_retire_or_store'],
          // buyer_retirement_policy: values["buyer_retirement_policy"],
          buyer_retirement_policy: null,
          buyer_vintage_cutoff: values['buyer_vintage_cutoff'],
          buyer_consumerproduct: values['buyer_consumerproduct'],
          development_usdt: values['development_usdt'],
          projectfinance_details: values['projectfinance_details'],
          projectfinance_usedcredithistory:
            values['projectfinance_usedcredithistory'],
          buyerjurisdiction: values['buyerjurisdiction'],
          project_jurisdictional_redd: values['project_jurisdictional_redd'],
          currency: values['currency'],
          projid_known: values['projid_known'],
          market_isspot: values['market_isspot'],
          market_isprimary: values['market_isprimary'],
          market_isforward: values['market_isforward'],
          market_issecondary: values['market_issecondary'],
          project_name: values['project_name'],
          project_noid_reason: values['project_noid_reason'],
          project_noid_reason_other: values['project_noid_reason_other'],
          project_noid_date_register: returnDateOrNull(values['project_noid_date_register']),
          project_country: values['project_country_id'],
          project_type: values['project_type'],
          project_3p_carbon_standard: values['project_3p_carbon_standard_id'] == 0 ? null : getStandardName(values['project_3p_carbon_standard_id']),
          project_3p_carbon_standard_id: values['project_3p_carbon_standard_id'] == 0 ? null : values['project_3p_carbon_standard_id'],
          project_methodology: values['project_methodology'],
          project_cobenefits: values['project_cobenefits'] && values['project_cobenefits'].length ? values['project_cobenefits'].join(',') : '',
          project_cobenefits_rel: getFormFieldArray(values['project_cobenefits']),
          project_sdgs: values['project_sdgs'] && values['project_sdgs'].length ? values['project_sdgs'].join(',') : '',
          project_sdgs_rel: getFormFieldArray(values['project_sdgs']),
          project_additional_certification: values['project_additional_certification'],
          trade_type: values['trade_type'],
          transfer_or_retire: values['transfer_or_retire'],
          credit_issuance_status: values['credit_issuance_status'],
          estimated_or_actual_delivery_date: returnDateOrNull(values['estimated_or_actual_delivery_date']),
        };
        // save new customer
        dispatch(addNewTrade({ data: newTrade, callback: toggle, user_email: user.email, current_url: window.location.href, respondent_name: user.respondent_name }));
        validation.resetForm();
      }
    },
  }); 

  useEffect(() => {
    getProjectList({ limit: 10, search: projectSearch });
  }, [projectSearch]);

  useEffect(() => {
    batch(() => {
      dispatch(getTypeList({ limit: 9999 }));
      dispatch(getCountryList({ limit: 9999 }));
      dispatch(getStandardList({ limit: 9999 }));
      dispatch(getCoBenefitList({ limit: 9999 }));
      dispatch(getSDGList({ limit: 9999 }));
      dispatch(getBuyerList({ respondent_id : user.respondent_id }));
    });
    
  }, []);

  const onSelectChange = (e, name) => {
    validation.setFieldValue(name, e.value);
    validation.handleChange(e);
  };

  const onMultiSelectChange = (e, name) => {
    const value = e.map( selection => selection.value );
    validation.setFieldValue(name, value);
  };

  const selectValue = (option, options) => {
    let isnum = /^\d+$/.test(option)
    if( isnum ){
      option = parseInt(option);
    }
    return options.find((el) => el.value === option);
  };

  

  const multiSelectValue = (selectedOptions, options) => {
    if( selectedOptions ){
      const selectedOptionsArr = selectedOptions.map( selected => options.find((el) => el.value == selected) );
      return selectedOptionsArr;
    } else {
      return undefined;
    }
  };

  const setBuyerField = () => {
    validation.setFieldValue("buyer", currentTrade?.buyer?.buyer_id);
  };

  const getFormattedPrice = (value) => {
    if( parseFloat(value).toFixed(2) != parseFloat(value) ){
      return parseFloat(value).toFixed(2);
    } else {
      return value;
    }
  }

  const getFormattedVolume = (value) => {
    if( parseFloat(value).toFixed(2) != parseFloat(value) ){
      return parseFloat(value).toFixed(2);
    } else {
      return value;
    }
  }

  const anticipatedDateShouldBeRequested = ( reason ) => {
    if( reason ){
      const selectedOption = ReasonNOIDOptions.find( (option) => option.value === reason )?.futureDate
      return selectedOption;
    } else {
      return false;
    }
  }

  const clearNoDateTransactionFields = () => {
    validation.setFieldValue("no_date_transaction", false);
    validation.setFieldValue("trade_year", null);
    validation.setFieldValue("trade_month", null);
    validation.setFieldValue("trade_quarter", null);
    validation.setFieldValue("trade_semester", null);
  }


  const setSelectOptions = (projectSearch) => {
    setProjectidVisible( projectSearch );
    axios
      .get(URL.get_projectList+'?limit=10&projectSearch='+projectSearch)
      .then((r) => {
        setSelectableProjectsList(r.data);
      }) 
      .catch((e) => console.log(e));
    
  }


  const setSelectedProject = (project_id, project_name, project_code) => {
    if( project_name ){
      if( project_name != 'NO.ID' ){
        project_name = project_code + ' - ' + project_name;
      }
      setProjectidVisible(project_name);
      setCurrentlySelectedProject(project_name);
      validation.setFieldValue("projectID", project_id);
    }
    setShowSelectableProjectsList(false);
  }

  let projectList = selectableProjectsList.map((project) =>  {
    if( project.project_code != 'NO.ID' ) {
      return <li tabIndex={1000 + project.projectid} key={project.projectid} onClick={() => {setSelectedProject(project.projectid, project.project_name, project.project_code); }}>{project.project_code} - {project.project_name}</li> 
    } else {
      return <li tabIndex={1000 + project.projectid} key={project.projectid} onClick={() => {setSelectedProject(project.projectid, project.project_name, project.project_code)}}>{project.project_name}</li>
    }
  });

  const validateDateTransaction = (value, validation) => {
    let isValid = true;
    // trying to set up this validation in a way such that if an update to formik results in the validation object no longer having the expected structure, 
    // the validation won't break the form.  Chad P. 2024-04-22
    try {
      let isValidTest = false;
      const date_transaction = validation?.from[0].value.date_transaction;
      const no_date_transaction = validation?.from[0].value.no_date_transaction;
      if( date_transaction || no_date_transaction ){
        isValidTest = true;
      } 
      isValid = isValidTest;
    } catch (e) {
      console.log(e);
    }
    return isValid;
  }

  const validateTradeYear = (value, validation) => {
    let isValid = true;

    // trying to set up this validation in a way such that if an update to formik results in the validation object no longer having the expected structure, 
    // the validation won't break the form.  Chad P. 2024-04-22
    try {

      let isValidTest = false;
    
      const date_transaction = validation?.from[0].value.date_transaction;
      if( date_transaction ){
        isValidTest = true;
      } else {
        const no_date_transaction = validation?.from[0].value.no_date_transaction;
        const trade_year = validation?.from[0].value.trade_year;
        // const trade_month = validation.from[0].value.trade_month;
        // const trade_quarter = validation.from[0].value.trade_quarter;
        // const trade_semester = validation.from[0].value.trade_semester;
        if( no_date_transaction && trade_year ){
            isValidTest = true;
            // if( validation.from[0].value.trade_month ){
            //   isValid = true;
            // } else if( validation.from[0].value.trade_quarter ){
            //   isValid = true;
            // } else if( validation.from[0].value.trade_semester ){
            //   isValid = true;
            // }
        }
      }

      isValid = isValidTest;

    } catch (e) {
      console.log(e);
    }
  
    return isValid;
  }

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="xl"
        className="border-0"
        modalClassName="modal fade zoomIn"
        onClosed={() => {
          setProjectidVisible(null);
          setCurrentlySelectedProject(null);
          validation.setFieldValue("projectID", null);
          setTradeDate("");
          onClosed();
        }}
        onOpened={() => {
          setBuyerField()
        }}
      >
        <ModalHeader className="p-3 bg-soft-info" toggle={toggle}>
          {!!isEdit ? "Edit Trade" : "Submit New Trade"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody className="modal-body">
            <Row className="pt-0">
              <Col>
                <div>
                  <h4 className="my-2">Complete for all transactions</h4>
                </div>
              </Col>
            </Row>
            <Row className="bg-soft-info p-1 py-3 mb-2">
              <Col lg={2}>
                <Label for="date_transaction-field" className="form-label">
                  Transaction date
                </Label>
                <Input
                  name="date_transaction"
                  id="date_transaction-field"
                  className="form-control"
                  placeholder="Transaction date"
                  type="date"
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  onChange={(e) => {
                    validation.handleChange(e);
                    setTradeDate(
                      moment.utc(e.target.value).format("yyyy-MM-DD")
                    );
                    setHasFullDate(1);
                    clearNoDateTransactionFields();
                  }}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.no_date_transaction ? "" : // if no date transaction is checked, date transaction is empty
                    moment
                      .utc(validation.values.date_transaction)
                      .format("yyyy-MM-DD") || ""
                  }
                  invalid={
                    validation.touched.date_transaction &&
                    validation.errors.date_transaction
                  }
                />
                
                {validation.touched.date_transaction &&
                  validation.errors.date_transaction && (
                    <FormFeedback type="invalid">
                      {validation.errors.date_transaction}
                    </FormFeedback>
                  )}
              </Col>
              <Col lg={5}>
                <Label for="projectid" className="form-label">
                  Project
                </Label>
                <Input
                  name="projectid_visible"
                  id="projectid_visible"
                  type="text"
                  autoComplete="off"
                  placeholder="...search for a project"
                  defaultValue={isEdit?.projectid === 0 || isEdit?.projectid?.project_code == 'NO.ID' ? 'NO.ID' : isEdit?.projectid?.project_code ? isEdit?.projectid?.project_code + ' - ' + isEdit?.projectid?.project_name : ''}
                  value={projectidVisible}
                  onChange={(e) => {
                    setSelectOptions(e.target.value);
                  }}
                  onFocus={() => {
                    setShowSelectableProjectsList(true);
                    if( projectidVisible ){
                      setCurrentlySelectedProject(projectidVisible); 
                    } else if( isEdit?.projectid?.project_code && isEdit?.projectid?.project_name ) {
                      if( isEdit?.projectid?.project_code == 'NO.ID' ){
                        setCurrentlySelectedProject('NO.ID');
                      } else {
                        setCurrentlySelectedProject(isEdit?.projectid?.project_code + ' - ' + isEdit?.projectid?.project_name);
                      }
                    }
                    setSelectOptions('');
                  }}
                  onBlur={(e) => {
                    if( e.relatedTarget && e.relatedTarget.tagName != 'LI' ){
                      setShowSelectableProjectsList(false);
                    }
                    if( currentlySelectedProject ){
                      setProjectidVisible(currentlySelectedProject);
                    } else {
                      setProjectidVisible('');
                    }
                  }}
                />
                <Input
                  name="projectID"
                  id="projectID"
                  type="hidden"
                  value={validation.values.projectid}
                />

                <ul id="selectableProjects" className={!showSelectableProjectsList ? 'd-none selectableProjects' : 'selectableProjects'} style={{ position: 'absolute', zIndex: '999', width: '94%'}} >{projectList}</ul> 

                
              </Col>
              <Col lg={5}>
                <div>
                  <Label For="market_classification" className="form-label">
                    Transaction Market Classification <i style={{ textTransform: 'none' }}>(VCM, Compliance)</i>
                  </Label>
                  <Select
                    value={selectValue(
                      validation.values.market_classification,
                      MarketClassificationOptions
                    )}
                    onChange={(e) => onSelectChange(e, "market_classification")}
                    options={MarketClassificationOptions}
                    name="market_classification"
                    classNamePrefix="select2-selection form-select"
                    invalid={
                      validation.touched.market_classification &&
                      validation.errors.market_classification
                    }
                  />
                  
                  {validation.touched.market_classification && validation.errors.market_classification ? (
                    <FormFeedback type="invalid" style={{display: 'block'}}>
                      {validation.errors.market_classification}
                    </FormFeedback>
                  ): null}
                </div>
              </Col>
              </Row>

              <Row className="bg-soft-info p-1 py-3 mb-2">
                <Col lg={5}>
                <div className="form-check form-switch form-switch-secondary mb-3">
                  <Input
                    name="no_date_transaction"
                    id="no_date_transaction-field"
                    placeholder="no_date_transaction"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    defaultValue={false}
                    onChange={() => {
                        
                        if( !validation.values.no_date_transaction ){
                          setTradeDate('');
                          validation.values.date_transaction = '';
                          setHasFullDate(0);
                        } else {
                          setHasFullDate(1);
                          validation.setFieldValue('trade_year', null);
                          validation.setFieldValue('trade_month', null);
                          validation.setFieldValue('trade_quarter', null);
                          validation.setFieldValue('trade_semester', null);
                        }
                        validation.setFieldValue(
                          "no_date_transaction",
                          !validation.values.no_date_transaction
                        )
                        
                      }
                    }
                    onBlur={validation.handleBlur}
                    value={validation.values.no_date_transaction || false}
                    checked={validation.values.no_date_transaction || false}
                    invalid={
                      validation.touched.no_date_transaction &&
                      validation.errors.no_date_transaction
                    }
                  />
                  <Label className="form-check-label" for="no_date_transaction-field">
                    Cannot provide specific date for this trade?
                  </Label>

                  {
                  validation.errors.no_date_transaction ? (
                    <FormFeedback type="invalid">
                      {validation.errors.no_date_transactiont}
                    </FormFeedback>
                  ) : null}
                </div>
                {validation.values.no_date_transaction && (
                  <div>Please provide a year for this trade (and month, quarter or semester if possible)</div>
                )}
                </Col>
                {validation.values.no_date_transaction && (
                  <><Col lg={3}>
                    <div>
                      <Label For="trade_year" className="form-label">
                        Trade Year
                      </Label>
                      <Select
                        value={selectValue(
                          validation.values.trade_year,
                          yearOptions
                        )}
                        onChange={(e) => { onSelectChange(e, "trade_year"); setTradeYear(e.value); }}
                        options={yearOptions}
                        name="trade_year"
                        id="trade_year"
                        classNamePrefix="select2-selection form-select"
                      />
                      {validation.touched.trade_year &&
                        validation.errors.trade_year ? (
                          <FormFeedback type="invalid" style={{display: 'block'}}>
                            {validation.errors.trade_year}
                          </FormFeedback>
                        ) : null}
                    </div>
                    <div>
                      <Label For="trade_quarter" className="form-label pt-3">
                        Trade Quarter
                      </Label>
                      <Select
                        value={selectValue(
                          validation.values.trade_quarter,
                          quarterOptions
                        )}
                        onChange={(e) => { onSelectChange(e, "trade_quarter"); setTradeQuarter(e.value); }}
                        options={quarterOptions}
                        name="trade_quarter"
                        id="trade_quarter"
                        classNamePrefix="select2-selection form-select"
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div>
                      <Label For="trade_month" className="form-label">
                        Trade Month
                      </Label>
                      <Select
                        value={selectValue(
                          validation.values.trade_month,
                          monthOptions
                        )}
                        onChange={(e) => { onSelectChange(e, "trade_month"); setTradeMonth(e.value); }}
                        options={monthOptions}
                        name="trade_month"
                        id="trade_month"
                        classNamePrefix="select2-selection form-select"
                      />
                    </div>
                    <div>
                      <Label For="trade_semester" className="form-label pt-3">
                        Trade Semester
                      </Label>
                      <Select
                        value={selectValue(
                          validation.values.trade_semester,
                          semesterOptions
                        )}
                        onChange={(e) => { onSelectChange(e, "trade_semester"); setTradeSemester(e.value); }}
                        options={semesterOptions}
                        name="trade_semester"
                        id="trade_semester"
                        classNamePrefix="select2-selection form-select"
                      />
                    </div>
                  </Col>
                  </>
                )}

                
              </Row>

              <Row className="bg-soft-info p-1 py-3 mb-2">
              <Col lg={5}>
                <Label for="buyer-field" className="form-label">
                  Buyer
                </Label>
                <Select
                  value={validation.values.buyer ? selectValue(
                    validation.values.buyer,
                    Buyer?.buyerList?.map((el) => ({
                    value: el?.buyer_id,
                    label: el?.buyer_name,
                  }))) : ''}
                  
                  onChange={(e) => onSelectChange(e, "buyer")}
                  isLoading={buyerLoading}
                  options={Buyer?.buyerList?.map((el) => ({
                    value: el?.buyer_id,
                    label: el?.buyer_name,
                  }))}
                  styles={{ menu: (base) => ({ ...base, zIndex: 3 }) }}
                  name="buyer"
                  id="buyer"
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.buyer && validation.errors.buyer && (
                  <FormFeedback type="invalid">
                    {validation.errors.buyer}
                  </FormFeedback>
                )}
                <button
                  type="button"
                  className="mt-2 pb-3 bg-transparent border-0 p-0 textPrimary"
                  id="create-btn"
                  onClick={() => setCreateUserModal(true)}
                >
                  Create Buyer
                </button>
              </Col>
              <Col lg={5} className="mb-4">
                <Label
                  for="end_user_or_intermediary-field"
                  className="form-label"
                >
                  End user or Intermediary?
                </Label>
                <Select
                  value={selectValue(
                    validation.values.end_user_or_intermediary,
                    EndUserOfIntermediaryOptions
                  )}
                  onChange={(e) =>
                    onSelectChange(e, "end_user_or_intermediary")
                  }
                  options={EndUserOfIntermediaryOptions}
                  name="end_user_or_intermediary"
                  id="end_user_or_intermediary"
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.end_user_or_intermediary &&
                  validation.errors.end_user_or_intermediary && (
                    <FormFeedback type="invalid">
                      {validation.errors.end_user_or_intermediary}
                    </FormFeedback>
                  )}
              </Col>
              { (validation.values.end_user_or_intermediary == 'Intermediary - takes ownership of offsets' ||
                  validation.values.end_user_or_intermediary == 'Intermediary - does NOT take ownership of offsets'
                ) && (
              <Row><Col lg={6} className="mb-4">
                <Label
                  for="name_of_end_user-field"
                  className="form-label"
                >
                  End User Name (if known)?
                </Label>
                <div className="input-group">
                  <Input
                    name="name_of_end_user"
                    id="name_of_end_user-field"
                    className="form-control"
                    type="text"
                    value={validation.values.name_of_end_user || ""}
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.name_of_end_user &&
                      validation.errors.name_of_end_user
                    }
                  />
                  {validation.touched.name_of_end_user &&
                    validation.errors.name_of_end_user && (
                      <FormFeedback type="invalid">
                        {validation.errors.name_of_end_user}
                      </FormFeedback>
                    )}
                </div>
              </Col>
              </Row>
              )}
              <Col lg={5} className="">
                  <Label
                    for="trade_type-field"
                    className="form-label"
                  >
                    Trade Type
                  </Label>
                  <Select
                    value={selectValue(
                      validation.values.trade_type,
                      TradeTypes
                    )}
                    onChange={(e) =>
                      onSelectChange(e, "trade_type")
                    }
                    options={TradeTypes}
                    name="trade_type"
                    id="trade_type-field"
                    classNamePrefix="select2-selection form-select"
                  />
                  {validation.touched.trade_type &&
                    validation.errors.trade_type && (
                      <FormFeedback type="invalid">
                        {validation.errors.trade_type}
                      </FormFeedback>
                    )}
              </Col>
              <Col lg={5} className="">
                  <Label
                    for="transfer_or_retire-field"
                    className="form-label"
                  >
                    Transfer or Retire?
                  </Label>
                  <Select
                    value={selectValue(
                      validation.values.transfer_or_retire,
                      TransferOrRetireOptions
                    )}
                    onChange={(e) =>
                      onSelectChange(e, "transfer_or_retire")
                    }
                    options={TransferOrRetireOptions}
                    name="transfer_or_retire"
                    id="transfer_or_retire-field"
                    classNamePrefix="select2-selection form-select"
                  />
                  {validation.touched.transfer_or_retire &&
                    validation.errors.transfer_or_retire && (
                      <FormFeedback type="invalid">
                        {validation.errors.transfer_or_retire}
                      </FormFeedback>
                    )}
              </Col>
            </Row>
            {validation.values.end_user_or_intermediary === "End User" && (
            <>
            <Row className="p-1 py-3 bg-soft-warning">
              <Col lg={4}>
                <Label for="buyeroffsetgoals" className="form-label">
                  Buyer's offset goals?
                </Label>
                <Select
                  value={selectValue(
                    validation.values.buyeroffsetgoals,
                    OffsetGoalsOptions
                  )}
                  onChange={(e) => onSelectChange(e, "buyeroffsetgoals")}
                  options={OffsetGoalsOptions}
                  name="buyeroffsetgoals"
                  id="buyeroffsetgoals"
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.buyeroffsetgoals &&
                  validation.errors.buyeroffsetgoals && (
                    <FormFeedback type="invalid">
                      {validation.errors.buyeroffsetgoals}
                    </FormFeedback>
                  )}
              </Col>
              <Col lg={4}>
                <Label for="buyerdepartment" className="form-label">
                  Buyer Department
                </Label>
                <Input
                  name="buyerdepartment"
                  id="buyerdepartment"
                  className="form-control"
                  placeholder=" Eg Sales, Operations, Facilities"
                  type="text"
                  validate={{
                    required: {
                      value: true,
                    },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.buyerdepartment || ""}
                  invalid={
                    validation.touched.buyerdepartment &&
                    validation.errors.buyerdepartment
                  }
                />
                {validation.touched.buyerdepartment &&
                  validation.errors.buyerdepartment && (
                    <FormFeedback type="invalid">
                      {validation.errors.buyerdepartment}
                    </FormFeedback>
                  )}
              </Col>
            </Row>
            </>
            )}

            {  ( validation.values.projectID == 0 
                || ( !validation.values.projectID && isEdit?.projectid?.projectid === 0 ) )
                &&
            (
              <div>
                <Row className="pt-2">
                  <Col>
                    <div>
                      <h4>
                        If no Project ID, provide the following attributes:
                      </h4>
                    </div>
                  </Col>
                </Row>
                <Row className="bg-soft-danger p-1 py-3 mb-2">
                  <Row className="my-2">
                  <Col lg={3} className="">
                    <Label
                      for="project_noid_reason-field"
                      className="form-label"
                    >
                      Reason No Project ID Provided
                    </Label>
                    <Select
                      value={selectValue(
                        validation.values.project_noid_reason,
                        ReasonNOIDOptions
                      )}
                      onChange={(e) => onSelectChange(e, "project_noid_reason")}
                      options={ReasonNOIDOptions}
                      name="project_noid_reason"
                      id="project_noid_reason-field"
                      classNamePrefix="select2-selection form-select"
                    />
                    {validation.touched.project_noid_reason &&
                      validation.errors.project_noid_reason && (
                        <FormFeedback type="invalid">
                          {validation.errors.project_noid_reason}
                        </FormFeedback>
                      )}
                  </Col>
                  {validation.values.project_noid_reason == "Other" && (
                    <Col lg={3}>
                      <Label
                        for="project_noid_reason_other-field"
                        className="form-label"
                      >
                        Other Reason
                      </Label>
                      <Input
                        name="project_noid_reason_other"
                        id="project_noid_reason_other-field"
                        className="form-control"
                        type="text"
                        value={validation.values.project_noid_reason_other}
                        invalid={
                          validation.touched.project_noid_reason_other &&
                          validation.errors.project_noid_reason_other
                        }
                        onChange={() => true}
                      />
                      {validation.touched.project_noid_reason_other &&
                        validation.errors.project_noid_reason_other && (
                          <FormFeedback type="invalid">
                            {validation.errors.project_noid_reason_other}
                          </FormFeedback>
                        )}
                    </Col>
                  )}
                  <Col lg={3} className="">
                    <Label for="project_name-field" className="form-label">
                      Project Name
                    </Label>
                    <div className="input-group">
                      <Input
                        name="project_name"
                        id="project_name-field"
                        className="form-control"
                        type="text"
                        value={validation.values.project_name || ""}
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.project_name &&
                          validation.errors.project_name
                        }
                      />
                      {validation.touched.project_name &&
                        validation.errors.project_name && (
                          <FormFeedback type="invalid">
                            {validation.errors.project_name}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>
                </Row>

                { anticipatedDateShouldBeRequested(validation.values.project_noid_reason) && (
                <Row className="my-2">
                  <Col lg={12}>
                    <Label
                      for="project_noid_date_register-field"
                      className="form-label"
                    >
                      When do you anticipate having a registered Project ID? (so
                      we can follow up with you)
                    </Label>
                    <Row>
                      <Col lg={2}>
                        <Input
                          name="project_noid_date_register"
                          id="project_noid_date_register-field"
                          className="form-control"
                          placeholder="Expected date"
                          type="date"
                          onChange={(e) => {
                            validation.handleChange(e);
                          }}
                          onBlur={validation.handleBlur}
                          value={
                            moment
                              .utc(validation.values.project_noid_date_register)
                              .format("yyyy-MM-DD") || ""
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>)}

                <Row className="my-2">
                  

                  <Col lg={3}>
                    <Label for="project_type" className="form-label">
                      Project Type
                    </Label>
                    <Select
                      value={selectValue(
                        validation.values.project_type,
                        typeList
                      )}
                      onChange={(e) => onSelectChange(e, "project_type")}
                      options={typeList}
                      placeholder="Project Type"
                      name="project_type"
                      id="project_type"
                      classNamePrefix="select2-selection form-select"
                    />
                    {validation.touched.project_type &&
                      validation.errors.project_type && (
                        <FormFeedback type="invalid">
                          {validation.errors.project_type}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col lg={3}>
                    <Label for="project_country_id" className="form-label">
                      Country
                    </Label>
                    <Select
                      value={selectValue(
                        validation.values.project_country_id,
                        countries
                      )}
                      onChange={(e) => onSelectChange(e, "project_country_id")}
                      options={countries}
                      placeholder="Country"
                      name="project_country_id"
                      id="project_country_id"
                      classNamePrefix="select2-selection form-select"
                    />
                    {validation.touched.project_country_id &&
                      validation.errors.project_country_id && (
                        <FormFeedback type="invalid">
                          {validation.errors.project_country_id}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col lg={3}>
                    <Label
                      for="project_3p_carbon_standard"
                      className="form-label"
                    >
                      Carbon Standard
                    </Label>

                    <Select
                      value={selectValue(
                        validation.values.project_3p_carbon_standard_id,
                        standardList
                      )}
                      onChange={(e) =>
                        onSelectChange(e, "project_3p_carbon_standard_id")
                      }
                      options={standardList}
                      placeholder="Carbon Standard"
                      name="project_3p_carbon_standard"
                      id="project_3p_carbon_standard"
                      classNamePrefix="select2-selection form-select"
                    />
                    {validation.touched.project_3p_carbon_standard &&
                      validation.errors.project_3p_carbon_standard && (
                        <FormFeedback type="invalid">
                          {validation.errors.project_3p_carbon_standard}
                        </FormFeedback>
                      )}
                  </Col>
                </Row>

                <Row className="my-2">
                  <Col lg={3} className="">
                    <Label
                      for="project_methodology-field"
                      className="form-label"
                    >
                      Methodology/Protocol
                    </Label>
                    <div className="input-group">
                      <Input
                        name="project_methodology"
                        id="project_methodology-field"
                        className="form-control"
                        type="text"
                        value={validation.values.project_methodology}
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.project_methodology &&
                          validation.errors.project_methodology
                        }
                      />
                      {validation.touched.project_methodology &&
                        validation.errors.project_methodology && (
                          <FormFeedback type="invalid">
                            {validation.errors.project_methodology}
                          </FormFeedback>
                        )}
                    </div>
                  </Col>

                  <Col lg={3}>
                    <Label for="project_cobenefits" className="form-label">
                      Co-benefits and/or SDG Standards (Select Multiple)
                                          </Label>
                                          <Select
                      value={multiSelectValue(
                        validation.values.project_cobenefits,
                        coBenefitList
                      )}
                      onChange={(e) => onMultiSelectChange(e, "project_cobenefits")}
                      options={coBenefitList}
                      placeholder="Cobenefits and/or SDG Standards"
                      name="project_cobenefits"
                      id="project_cobenefits"
                      classNamePrefix="select2-selection form-select"
                      isMulti={true}
                    />
                    {validation.touched.project_cobenefits &&
                      validation.errors.project_cobenefits && (
                        <FormFeedback type="invalid">
                          {validation.errors.project_cobenefits}
                        </FormFeedback>
                      )}
                  </Col>

                  <Col lg={3}>
                    <Label for="project_sdgs" className="form-label">
                      SDGs Certified <i style={{ textTransform: 'none' }}>(Select Multiple)</i>
                    </Label>
                    <Select
                      value={multiSelectValue(
                        validation.values.project_sdgs,
                        sdgList
                      )}
                      onChange={(e) => onMultiSelectChange(e, "project_sdgs")}
                      options={sdgList}
                      placeholder="SDGs Certified"
                      name="project_sdgs"
                      id="project_sdgs"
                      classNamePrefix="select2-selection form-select"
                      isMulti={true}
                    />
                    {validation.touched.project_sdgs &&
                      validation.errors.project_sdgs && (
                        <FormFeedback type="invalid">
                          {validation.errors.project_sdgs}
                        </FormFeedback>
                      )}
                  </Col>

                  
                </Row>
                </Row>
              </div>
            )}

           
              
            <Row className="bg-soft-success p-1 py-3 my-2">
              
              <Col lg={3} className="">
                <Label for="volume_tco2e-field" className="form-label">
                  Volume Sold (TCO2e)
                </Label>
                <div className="input-group">
                  <Cleave
                    placeholder="123456"
                    // options={{
                    //   numeral: true,
                    // }}
                    className="form-control"
                    value={getFormattedVolume(validation.values.volume_tco2e) || ""}
                    name="volume_tco2e"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  <span className="input-group-text">TCO2e</span>
                  {validation.touched.volume_tco2e &&
                    validation.errors.volume_tco2e ? (
                      <FormFeedback type="invalid" style={{display: 'block'}}>
                        {validation.errors.volume_tco2e}
                      </FormFeedback>
                    ) : null}
                </div>
              </Col>

              <Col lg={2}>
                <Label for="price_local-field" className="form-label">
                  Price per Tonne
                </Label>
                <div className="input-group">
                  <Input
                    name="price_local"
                    id="price_local-field"
                    className="form-control"
                    placeholder="1.23"
                    type="number"
                    step="0.01"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={getFormattedPrice(validation.values.price_local) || ""}
                    invalid={
                      validation.touched.price_local &&
                      validation.errors.price_local
                    }
                  />
                  {validation.touched.price_local &&
                    validation.errors.price_local ? (
                      <FormFeedback type="invalid" style={{display: 'block'}}>
                        {validation.errors.price_local}
                      </FormFeedback>
                    ) : null}
                </div>
              </Col>

              <Col lg={2}>
                <Label for="currency-field" className="form-label">
                  Currency
                </Label>
                <Select
                  value={selectValue(
                    validation.values.currency,
                    currencyOptions
                  )}
                  placeholder={
                    !validation.values.date_transaction && !validation.values.trade_year && !validation.values.trade_quarter && !validation.values.trade_semester
                      ? "Select transaction date first"
                      : "Select Currency"
                  }
                  isDisabled={!validation.values.date_transaction && !validation.values.trade_year && !validation.values.trade_quarter && !validation.values.trade_semester}
                  
                  onChange={(e) => { 
                    onSelectChange(e, "currency");
                    onSelectChange(
                      getValueUSD(
                        e.value,
                        validation.values.volume_tco2e,
                        validation.values.price_local
                      ) || 0,
                      //getCurrencyRates(e.value)?.toString() *
                      //  Number(clearNumber(validation.values.volume_tco2e)) *
                      //  Number(validation.values.price_usd_tcoe) || 0,
                      "value_usd"
                    );
                    
                  }}
                  options={currencyOptions}
                  name="currency"
                  classNamePrefix="select2-selection form-select"
                  invalid={
                    validation.touched.currency &&
                    validation.errors.currency
                  }
                />
                
                {validation.touched.currency && validation.errors.currency ? (
                  <FormFeedback type="invalid" style={{display: 'block'}}>
                    {validation.errors.currency}
                  </FormFeedback>
                ): null}
              </Col>

              <Col lg={2} className="d-none">
                <Label for="conversion-field" className="form-label">
                  Conversion Rate to USD
                </Label>
                <Input
                  name="conversion"
                  id="conversion-field"
                  className="form-control"
                  placeholder="Conversion Rate"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={getCurrencyRates(
                    validation.values.currency
                  )?.toString() == 'NaN'? 1 : getCurrencyRates(
                    validation.values.currency
                  )?.toString() }
                  invalid={
                    validation.touched.conversion &&
                    validation.errors.conversion
                  }
                />
                {validation.touched.conversion &&
                  validation.errors.conversion && (
                    <FormFeedback type="invalid">
                      {validation.errors.conversion}
                    </FormFeedback>
                  )}
              </Col>

              <Col lg={2}>
                <Label for="value_usd-field" className="form-label">
                  Trade Value (USD)
                </Label>
                <Input
                  name="value_usd"
                  id="value_usd-field"
                  className="form-control"
                  placeholder="Total value (USD)"
                  type="number"
                  validate={{
                    required: {
                      value: true,
                    },
                  }}
                  // onChange={validation.handleChange}
                  // onBlur={validation.handleBlur}
                  value={
                    getValueUSD(
                      validation.values.currency,
                      validation.values.volume_tco2e,
                      validation.values.price_local
                    ) || 0
                    //getCurrencyRates(validation.values.currency)?.toString() *
                    //  Number(clearNumber(validation.values.volume_tco2e)) *
                    //  Number(validation.values.price_usd_tcoe) || 0
                  }
                  invalid={
                    validation.touched.value_usd && validation.errors.value_usd
                  }
                  onChange={() => true}
                />
                {validation.touched.value_usd &&
                  validation.errors.value_usd && (
                    <FormFeedback type="invalid">
                      {validation.errors.value_usd}
                    </FormFeedback>
                  )}
              </Col>
              
            </Row>

            <Row className="bg-soft-info p-1 py-3 mb-2">
              <Col lg={4}>
                <Label for="vintage_year-field" className="form-label">
                  Vintage Year (or Range)
                </Label>
                <Input
                  placeholder="YYYY or YYYY-YYYY"
                  type="text"
                  value={validation.values.vintage_year}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  name="vintage_year"
                  className="form-control"
                  
                  invalid={
                    validation.touched.vintage_year &&
                    validation.errors.vintage_year
                  }
                />
                {validation.touched.vintage_year &&
                validation.errors.vintage_year ? (
                  <FormFeedback type="invalid">
                    {validation.errors.vintage_year}
                  </FormFeedback>
                ) : null}
              </Col>

              <Col lg={4}>
                <Label for="credit_issuance_status-field" className="form-label">
                  Credit Issued Status
                </Label>
                <Select
                    value={selectValue(
                      validation.values.credit_issuance_status,
                      CreditIssuanceOptions
                    )}
                    onChange={(e) =>
                      onSelectChange(e, "credit_issuance_status")
                    }
                    options={CreditIssuanceOptions}
                    name="credit_issuance_status"
                    id="credit_issuance_status-field"
                    classNamePrefix="select2-selection form-select"
                  />
                  {validation.touched.credit_issuance_status &&
                    validation.errors.credit_issuance_status && (
                      <FormFeedback type="invalid">
                        {validation.errors.credit_issuance_status}
                      </FormFeedback>
                    )}
              </Col>
              
              <Col lg={4} className="">
                  <Label
                    for="project_additional_certification-field"
                    className="form-label"
                  >
                    Additional Certifications
                  </Label>
                  <Select
                    value={selectValue(
                      validation.values.project_additional_certification,
                      AdditionalCertifications
                    )}
                    onChange={(e) =>
                      onSelectChange(e, "project_additional_certification")
                    }
                    options={AdditionalCertifications}
                    name="project_additional_certification"
                    id="project_additional_certification-field"
                    classNamePrefix="select2-selection form-select"
                  />
                  {validation.touched.project_additional_certification &&
                    validation.errors.project_additional_certification && (
                      <FormFeedback type="invalid">
                        {validation.errors.project_additional_certification}
                      </FormFeedback>
                    )}
              </Col>
            </Row>

            <Row className="p-1 py-3 bg-soft-warning">
              <Col lg={2}>
                <div className="form-check form-switch form-switch-secondary mb-3">
                  <Input
                    name="market_isspot"
                    id="market_isspot-field"
                    placeholder="market_isspot"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={() =>
                      validation.setFieldValue(
                        "market_isspot",
                        !validation.values.market_isspot
                      )
                    }
                    onBlur={validation.handleBlur}
                    value={validation.values.market_isspot || false}
                    checked={validation.values.market_isspot || false}
                    invalid={
                      validation.touched.market_isspot &&
                      validation.errors.market_isspot
                    }
                  />
                  <Label className="form-check-label" for="market_isspot-field">
                    Spot market?
                  </Label>

                  {validation.touched.market_isspot &&
                  validation.errors.market_isspot ? (
                    <FormFeedback type="invalid">
                      {validation.errors.market_isspot}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={2} className="me-3">
                <div className="form-check form-switch form-switch-secondary mb-3">
                  <Input
                    name="market_isforward"
                    id="market_isforward-field"
                    placeholder="market_isforward"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={() =>
                      validation.setFieldValue(
                        "market_isforward",
                        !validation.values.market_isforward
                      )
                    }
                    onBlur={validation.handleBlur}
                    checked={validation.values.market_isforward || false}
                    invalid={
                      validation.touched.market_isforward &&
                      validation.errors.market_isforward
                    }
                  />
                  <Label
                    for="market_isforward-field"
                    className="form-check-label"
                  >
                    Forward market?
                  </Label>
                  {validation.touched.market_isforward &&
                  validation.errors.market_isforward ? (
                    <FormFeedback type="invalid">
                      {validation.errors.market_isforward}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={2}>
                <div className="form-check form-switch form-switch-info mb-3">
                  <Input
                    name="market_isprimary"
                    id="market_isprimary-field"
                    placeholder="market_isprimary"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={() =>
                      validation.setFieldValue(
                        "market_isprimary",
                        !validation.values.market_isprimary
                      )
                    }
                    onBlur={validation.handleBlur}
                    value={validation.values.market_isprimary || false}
                    checked={validation.values.market_isprimary || false}
                    disabled={validation.values.market_issecondary || false}
                    invalid={
                      validation.touched.market_isprimary &&
                      validation.errors.market_isprimary
                    }
                  />
                  <Label
                    className="form-check-label"
                    for="market_isprimary-field"
                  >
                    Primary market?
                  </Label>
                  {validation.touched.market_isprimary &&
                    validation.errors.market_isprimary && (
                      <FormFeedback type="invalid">
                        {validation.errors.market_isprimary}
                      </FormFeedback>
                    )}
                </div>
              </Col>

              <Col lg={3}>
                <div className="form-check form-switch form-switch-info mb-3">
                  <Input
                    name="market_issecondary"
                    id="market_issecondary-field"
                    placeholder="market_issecondary"
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={() =>
                      validation.setFieldValue(
                        "market_issecondary",
                        !validation.values.market_issecondary
                      )
                    }
                    disabled={validation.values.market_isprimary || false}
                    onBlur={validation.handleBlur}
                    value={validation.values.market_issecondary || false}
                    checked={validation.values.market_issecondary || false}
                    invalid={
                      validation.touched.market_issecondary &&
                      validation.errors.market_issecondary
                    }
                  />
                  <Label
                    for="market_issecondary-field"
                    className="form-check-label"
                  >
                    Secondary market?
                  </Label>
                  {validation.touched.market_issecondary &&
                    validation.errors.market_issecondary && (
                      <FormFeedback type="invalid">
                        {validation.errors.market_issecondary}
                      </FormFeedback>
                    )}
                </div>
              </Col>
            </Row>

            {validation.values.market_isspot && (
              <Row className="bg-soft-warning p-1 pb-3 mb-2" id="if_is_forwardMarket">
                  <Col lg={4}>
                    <Label
                      for="estimated_or_actual_delivery_date-field"
                      className="form-label"
                    >
                      Estimated or Actual Delivery Date
                    </Label>
                    <Input
                      name="estimated_or_actual_delivery_date"
                      id="estimated_or_actual_delivery_date-field"
                      className="form-control"
                      placeholder="estimated_or_actual_delivery_date"
                      type="date"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      value={
                        moment.utc(
                          validation.values.estimated_or_actual_delivery_date
                        ).format("yyyy-MM-DD") || ""
                      }
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.estimated_or_actual_delivery_date &&
                        validation.errors.estimated_or_actual_delivery_date
                      }
                    />
                    {validation.touched.estimated_or_actual_delivery_date &&
                      validation.errors.estimated_or_actual_delivery_date && (
                        <FormFeedback type="invalid">
                          {validation.errors.estimated_or_actual_delivery_date}
                        </FormFeedback>
                      )}
                  </Col>
              </Row>
            )}

            {validation.values.market_isprimary && (
              

              <Row className="bg-soft-warning p-1 pb-3 mb-2" id="if_is_forwardMarket">

                <Row className="mb-3">
                  <Col sm={3}>
                    <Label for="development_usdt" className="form-label">
                    Cost of Project Development
                    </Label>
                  </Col>
                  <Col sm lg={3}>
                    <div className="input-group">
                      {/*<Input*/}
                      {/*  name="development_usdt"*/}
                      {/*  id="development_usdt-field"*/}
                      {/*  className="form-control"*/}
                      {/*  placeholder="56.78"*/}
                      {/*  type="number"*/}
                      {/*  validate={{*/}
                      {/*    required: {*/}
                      {/*      value: true,*/}
                      {/*    },*/}
                      {/*  }}*/}
                      {/*  onChange={validation.handleChange}*/}
                      {/*  onBlur={validation.handleBlur}*/}
                      {/*  value={validation.values.development_usdt || ""}*/}
                      {/*  invalid={*/}
                      {/*    validation.touched.development_usdt &&*/}
                      {/*    validation.errors.development_usdt*/}
                      {/*  }*/}
                      {/*/>*/}
                      <Cleave
                        name="development_usdt"
                        id="development_usdt"
                        className="form-control"
                        placeholder="56.78"
                        options={{
                          numeral: true,
                        }}
                        value={validation.values.development_usdt}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                      />
                      {validation.touched.development_usdt &&
                        validation.errors.development_usdt && (
                          <FormFeedback type="invalid">
                            {validation.errors.development_usdt}
                          </FormFeedback>
                        )}
                      <span className="input-group-text">USD $ / Tonne</span>
                    </div>
                  </Col>
                </Row>


                <div className="mb-2">
                  <h4>Bonus: Project Developer Questions</h4>
                </div>
                <Col lg={12}>
                  
                  <Row className="mb-3">
                    <Col sm={3}>
                      <Label
                        for="projectfinance_details-field"
                        className="form-label"
                      >
                        Project Finance details
                      </Label>
                    </Col>
                    <Col sm>
                      <Input
                        name="projectfinance_details"
                        id="projectfinance_details-field"
                        className="form-control"
                        placeholder="Eg CapEx, Upfront, Prepaid, Loan..."
                        type="text"
                        validate={{
                          required: {
                            value: true,
                          },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.projectfinance_details || ""}
                        invalid={
                          validation.touched.projectfinance_details &&
                          validation.errors.projectfinance_details
                        }
                      />
                      {validation.touched.projectfinance_details &&
                        validation.errors.projectfinance_details && (
                          <FormFeedback type="invalid">
                            {validation.errors.projectfinance_details}
                          </FormFeedback>
                        )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col sm={3}>
                      <Label
                        for="projectfinance_usedcredithistory-field"
                        className="form-label"
                      >
                        Was your (proj. dev.) credit history important for project
                        financing?
                      </Label>
                    </Col>
                    <Col>
                      <div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="projectfinance_usedcredithistory_option_1"
                            value={"Yes"}
                            checked={
                              validation.values
                                .projectfinance_usedcredithistory === "Yes"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "projectfinance_usedcredithistory",
                                "Yes"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="projectfinance_usedcredithistory_option_1"
                          >
                            Yes
                          </Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="projectfinance_usedcredithistory_option_2"
                            value={"No"}
                            checked={
                              validation.values
                                .projectfinance_usedcredithistory === "No"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "projectfinance_usedcredithistory",
                                "No"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="projectfinance_usedcredithistory_option_2"
                          >
                            No
                          </Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="projectfinance_usedcredithistory_option_3"
                            value={"Unknown"}
                            checked={
                              validation.values
                                .projectfinance_usedcredithistory === "Unknown"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "projectfinance_usedcredithistory",
                                "Unknown"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="projectfinance_usedcredithistory_option_3"
                          >
                            Unknown
                          </Label>
                        </div>
                      </div>
                      {validation.touched.projectfinance_usedcredithistory &&
                        validation.errors.projectfinance_usedcredithistory && (
                          <FormFeedback type="invalid">
                            {validation.errors.projectfinance_usedcredithistory}
                          </FormFeedback>
                        )}
                    </Col>
                  </Row>
                  {/* <Row className="mb-3">
                        <Col lg={4}>
                          <Label
                            for="buyer_internal_or_resell-field"
                            className="form-label"
                          >
                            Are the credits jurisdictional-scale?
                          </Label>
                          <div>
                            <div className="form-check form-check-inline">
                              <Input
                                className="form-check-input"
                                type="radio"
                                id="buyerjurisdiction_option_1"
                                value={"Yes"}
                                checked={validation.values.buyerjurisdiction === "Yes"}
                                onChange={() => true}
                                onClick={(e) => {
                                  validation.setFieldValue("buyerjurisdiction", "Yes");
                                }}
                              />
                              <Label
                                className="form-check-label"
                                for="buyerjurisdiction_option_1"
                              >
                                Yes
                              </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="radio"
                              id="buyerjurisdiction_option_2"
                              value={"No"}
                              checked={validation.values.buyerjurisdiction === "No"}
                              onChange={() => true}
                              onClick={(e) => {
                                validation.setFieldValue("buyerjurisdiction", "No");
                              }}
                            />
                            <Label
                              className="form-check-label"
                              for="buyerjurisdiction_option_2"
                            >
                              No
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="radio"
                              id="buyerjurisdiction_option_3"
                              value={"Unknown"}
                              checked={
                                validation.values.buyerjurisdiction === "Unknown"
                              }
                              onChange={() => true}
                              onClick={(e) => {
                                validation.setFieldValue(
                                  "buyerjurisdiction",
                                  "Unknown"
                                );
                              }}
                            />
                            <Label
                              className="form-check-label"
                              for="buyerjurisdiction_option_3"
                            >
                              Unknown
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="radio"
                              id="buyerjurisdiction_option_4"
                              value={"Not applicable"}
                              checked={
                                validation.values.buyerjurisdiction === "Not applicable"
                              }
                              onChange={() => true}
                              onClick={(e) => {
                                validation.setFieldValue(
                                  "buyerjurisdiction",
                                  "Not applicable"
                                );
                              }}
                            />
                            <Label
                              className="form-check-label"
                              for="buyerjurisdiction_option_4"
                            >
                              Not applicable
                            </Label>
                          </div>
                        </div>
                        {validation.touched.buyer_internal_or_resell &&
                        validation.errors.buyer_internal_or_resell && (
                          <FormFeedback type="invalid">
                            {validation.errors.buyer_internal_or_resell}
                          </FormFeedback>
                        )}
                    </Col>

                  </Row>
                  <Row className="mb-3">
                    <Col sm={3}>
                      <Label
                        for="project_jurisdictional_redd-field"
                        className="form-label"
                      >
                        Jurisdictional REDD
                      </Label>
                    </Col>
                    <Col>
                      <div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="project_jurisdictional_redd_option_1"
                            value={"Yes"}
                            checked={
                              validation.values.project_jurisdictional_redd ===
                              "Yes"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "project_jurisdictional_redd",
                                "Yes"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="project_jurisdictional_redd_option_1"
                          >
                            Yes
                          </Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="project_jurisdictional_redd_option_2"
                            value={"No"}
                            checked={
                              validation.values.project_jurisdictional_redd ===
                              "No"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "project_jurisdictional_redd",
                                "No"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="project_jurisdictional_redd_option_2"
                          >
                            No
                          </Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="project_jurisdictional_redd_option_3"
                            value={"Unknown"}
                            checked={
                              validation.values.project_jurisdictional_redd ===
                              "Unknown"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "project_jurisdictional_redd",
                                "Unknown"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="project_jurisdictional_redd_option_3"
                          >
                            Unknown
                          </Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            className="form-check-input"
                            type="radio"
                            id="project_jurisdictional_redd_option_3"
                            value={"N/A"}
                            checked={
                              validation.values.project_jurisdictional_redd ===
                              "N/A"
                            }
                            onChange={() => true}
                            onClick={(e) => {
                              validation.setFieldValue(
                                "project_jurisdictional_redd",
                                "N/A"
                              );
                            }}
                          />
                          <Label
                            className="form-check-label"
                            for="project_jurisdictional_redd_option_3"
                          >
                            N/A
                          </Label>
                        </div>
                      </div>
                      {validation.touched.project_jurisdictional_redd &&
                        validation.errors.project_jurisdictional_redd && (
                          <FormFeedback type="invalid">
                            {validation.errors.project_jurisdictional_redd}
                          </FormFeedback>
                        )}
                    </Col>
                  </Row> */}
              </Col>
            </Row>
            
            )}

            {validation.values.market_isforward && (
              <Row
                className="bg-soft-warning p-1 pb-3 mb-2"
                id="if_is_forwardMarket"
              >
                <Col lg={3}>
                  <Label
                    for="forwardcontract_options-field"
                    className="form-label"
                  >
                    Forward Percentage options
                  </Label>
                  <div className="input-group">
                    <Input
                      name="forwardcontract_options"
                      id="forwardcontract_options-field"
                      className="form-control"
                      placeholder="33.3"
                      type="number"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.forwardcontract_options || ""}
                      invalid={
                        validation.touched.forwardcontract_options &&
                        validation.errors.forwardcontract_options
                      }
                    />
                    {validation.touched.forwardcontract_options &&
                      validation.errors.forwardcontract_options && (
                        <FormFeedback type="invalid">
                          {validation.errors.forwardcontract_options}
                        </FormFeedback>
                      )}
                    <span className="input-group-text">%</span>
                  </div>
                </Col>

                <Col lg={4}>
                  <Label
                    for="forwardcontract_maturitydate-field"
                    className="form-label"
                  >
                    Contract Maturity Date
                  </Label>
                  <Input
                    name="forwardcontract_maturitydate"
                    id="forwardcontract_maturitydate-field"
                    className="form-control"
                    placeholder="forwardcontract_maturitydate"
                    type="date"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    value={
                      moment.utc(
                        validation.values.forwardcontract_maturitydate
                      ).format("yyyy-MM-DD") || ""
                    }
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.forwardcontract_maturitydate &&
                      validation.errors.forwardcontract_maturitydate
                    }
                  />
                  {validation.touched.forwardcontract_maturitydate &&
                    validation.errors.forwardcontract_maturitydate && (
                      <FormFeedback type="invalid">
                        {validation.errors.forwardcontract_maturitydate}
                      </FormFeedback>
                    )}
                </Col>
              </Row>
            )}

            {validation.values.end_user_or_intermediary === "End User" && (
            <>

            <Row className="pt-4">
              <Col>
                <div>
                  <h4>Bonus: About the Buyer</h4>
                </div>
              </Col>
            </Row>
            

            <Row className="bg-soft-danger p-1 py-3 mb-2">
              <div>
                <h4>Scopes</h4>
                <p>
                  What percentage of each type of activities do these credits
                  offset?
                </p>
              </div>
              {/*
              <Col lg={4}>
                <Label for="scopes-field" className="form-label">
                  scopes
                </Label>
                <Input
                  name="scopes"
                  id="scopes-field"
                  className="form-control"
                  placeholder="scopes"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.scopes || ""}
                  invalid={
                    validation.touched.scopes && validation.errors.scopes
                      ? true
                      : false
                  }
                />
                {validation.touched.scopes && validation.errors.scopes ? (
                  <FormFeedback type="invalid">
                    {validation.errors.scopes}
                  </FormFeedback>
                ) : null}
              </Col> */}

              <Col lg={4}>
                <Label for="scope_1-field" className="visually-hidden">
                  Scope 1
                </Label>
                <div className="input-group">
                  <span className="input-group-text">
                    Scope 1 {/* <i className="mdi mdi-circle-outline"></i> */}
                  </span>
                  {/*<Input*/}
                  {/*  name="scope_1"*/}
                  {/*  id="scope_1-field"*/}
                  {/*  className="form-control"*/}
                  {/*  placeholder="Scope 1"*/}
                  {/*  type="number"*/}
                  {/*  validate={{*/}
                  {/*    required: {*/}
                  {/*      value: true,*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*  onChange={(e) => {*/}
                  {/*    if(/^\d*$/g.test(e.target.value) ) validation.handleChange(e)*/}
                  {/*  }}*/}
                  {/*  onBlur={validation.handleBlur}*/}
                  {/*  value={validation.values.scope_1 || ""}*/}
                  {/*  invalid={*/}
                  {/*    validation.touched.scope_1 && validation.errors.scope_1*/}
                  {/*  }*/}
                  {/*/>*/}
                  <Cleave
                    name="scope_1"
                    id="scope_1-field"
                    className="form-control"
                    placeholder="Scope 1"
                    options={{
                      numeral: true,
                    }}
                    value={validation.values.scope_1}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.scope_1 && validation.errors.scope_1 ? (
                    <FormFeedback type="invalid">
                      {validation.errors.scope_1}
                    </FormFeedback>
                  ) : null}
                  <span className="input-group-text">%</span>
                </div>
                <div className="form-text text-black">
                  Direct emissions from owned or controlled sources
                </div>
              </Col>

              <Col lg={4}>
                {/* <Label for="scope_2-field" className="form-label">
                  Scope 2
                </Label> */}
                <div className="input-group">
                  <span className="input-group-text">
                    Scope 2 {/* <i className="mdi mdi-circle-outline"></i> */}
                  </span>
                  {/*<Input*/}
                  {/*  name="scope_2"*/}
                  {/*  id="scope_2-field"*/}
                  {/*  className="form-control"*/}
                  {/*  placeholder="Scope 2"*/}
                  {/*  type="number"*/}
                  {/*  validate={{*/}
                  {/*    required: {*/}
                  {/*      value: true,*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*  onChange={(e) => /^[0-9]*$/g.test(e.target.value) && validation.handleChange(e)}*/}
                  {/*  onBlur={validation.handleBlur}*/}
                  {/*  value={validation.values.scope_2 || ""}*/}
                  {/*  invalid={*/}
                  {/*    validation.touched.scope_2 && validation.errors.scope_2*/}
                  {/*  }*/}
                  {/*/>*/}
                  <Cleave
                    name="scope_2"
                    id="scope_2-field"
                    options={{
                      numeral: true,
                    }}
                    className="form-control"
                    placeholder="Scope 2"
                    value={validation.values.scope_2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.scope_2 && validation.errors.scope_2 ? (
                    <FormFeedback type="invalid">
                      {validation.errors.scope_2}
                    </FormFeedback>
                  ) : null}
                  <span className="input-group-text">%</span>
                </div>
                <div className="form-text text-black">
                  Indirect emissions from the generation of purchased
                  electricity, heating, cooling consumed.
                </div>
              </Col>

              <Col lg={4}>
                <div className="input-group">
                  <span className="input-group-text">
                    Scope 3 {/* <i className="mdi mdi-circle-outline"></i> */}
                  </span>
                  {/*<Input*/}
                  {/*  type="number"*/}
                  {/*  className="form-control"*/}
                  {/*  name="scope_3"*/}
                  {/*  id="scope_3-field"*/}
                  {/*  placeholder="Scope 3"*/}
                  {/*  validate={{*/}
                  {/*    required: {*/}
                  {/*      value: true,*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*  onChange={(e) => /^[0-9]*$/g.test(e.target.value) && validation.handleChange(e)}*/}
                  {/*  onBlur={validation.handleBlur}*/}
                  {/*  value={validation.values.scope_3 || ""}*/}
                  {/*  invalid={*/}
                  {/*    validation.touched.scope_3 && validation.errors.scope_3*/}
                  {/*  }*/}
                  {/*/>*/}
                  <Cleave
                    name="scope_3"
                    id="scope_3-field"
                    className="form-control"
                    placeholder="Scope 3"
                    options={{
                      numeral: true,
                    }}
                    value={validation.values.scope_3}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                  />
                  {validation.touched.scope_3 && validation.errors.scope_3 && (
                    <FormFeedback type="invalid">
                      {validation.errors.scope_3}
                    </FormFeedback>
                  )}
                  <span className="input-group-text">%</span>
                </div>
                <div className="form-text text-black">
                  All other indirect emissions that occur in a company's value
                  chain
                </div>
                {/*
                <Label for="scope_3-field" className="form-label">
                Scope 3
                </Label>
                <Input
                  name="scope_3"
                  id="scope_3-field"
                  className="form-control"
                  placeholder="scope_3"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.scope_3 || ""}
                  invalid={
                    validation.touched.scope_3 && validation.errors.scope_3
                      ? true
                      : false
                  }
                />
                {validation.touched.scope_3 && validation.errors.scope_3 ? (
                  <FormFeedback type="invalid">
                    {validation.errors.scope_3}
                  </FormFeedback>
                ) : null} */}
              </Col>

              {Boolean(validation.values.scope_3) && (
                <Col lg={12} className="mt-4">
                  <Label for="scope3_activity-field" className="form-label">
                    Scope 3 activity
                  </Label>
                  <Input
                    name="scope3_activity"
                    id="scope3_activity-field"
                    className="form-control"
                    placeholder="What is being offset?"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                      },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.scope3_activity || ""}
                    invalid={
                      validation.touched.scope3_activity &&
                      validation.errors.scope3_activity
                    }
                  />
                  {validation.touched.scope3_activity &&
                    validation.errors.scope3_activity && (
                      <FormFeedback type="invalid">
                        {validation.errors.scope3_activity}
                      </FormFeedback>
                    )}
                </Col>
              )}
            </Row>

            <Row className="bg-soft-info p-1 py-3 mb-2" id="usage">
              <div>
                <h4>Usage</h4>
              </div>
              

              
                  {/* <Col lg={4} className="mb-4 px-3">
                    <Label
                      for="buyer_internal_or_resell-field"
                      className="form-label"
                    >
                      Was purchase for internal use, or to re-sell for future
                      CORSIA compliance?
                    </Label>
                    <div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          value={"Internal"}
                          checked={
                            validation.values.buyer_internal_or_resell ===
                            "Internal"
                          }
                          onChange={() => true}
                          onClick={(e) => {
                            validation.setFieldValue(
                              "buyer_internal_or_resell",
                              "Internal"
                            );
                          }}
                        />
                        <Label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Internal
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          value={"Resell"}
                          id="flexRadioDefault2"
                          checked={
                            validation.values.buyer_internal_or_resell ===
                            "Resell"
                          }
                          onChange={() => true}
                          onClick={() =>
                            validation.setFieldValue(
                              "buyer_internal_or_resell",
                              "Resell"
                            )
                          }
                        />
                        <Label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Resell
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          value={"Unknown"}
                          id="flexRadioDefault3"
                          checked={
                            validation.values.buyer_internal_or_resell ===
                            "Unknown"
                          }
                          onChange={() => true}
                          onClick={() =>
                            validation.setFieldValue(
                              "buyer_internal_or_resell",
                              "Unknown"
                            )
                          }
                        />
                        <Label
                          className="form-check-label"
                          for="flexRadioDefault3"
                        >
                          Unknown
                        </Label>
                      </div>
                    </div>
                    {validation.touched.buyer_internal_or_resell &&
                    validation.errors.buyer_internal_or_resell ? (
                      <FormFeedback type="invalid">
                        {validation.errors.buyer_internal_or_resell}
                      </FormFeedback>
                    ) : null}
                  </Col> */}

                  <Col lg={4} className="mb-4">
                    <Label
                      for="buyer_retire_or_store-field"
                      className="form-label"
                    >
                      Retirement: Immediately or in the Future?
                    </Label>
                    <div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          // name="flexRadioDefault"
                          id="buyer_retire_or_store_1"
                          checked={
                            validation.values.buyer_retire_or_store ===
                            "Immediately"
                          }
                          onChange={() => true}
                          onClick={() =>
                            validation.setFieldValue(
                              "buyer_retire_or_store",
                              "Immediately"
                            )
                          }
                        />
                        <Label
                          className="form-check-label"
                          for="buyer_retire_or_store_1"
                        >
                          Immediately
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          // name="flexRadioDefault"
                          id="buyer_retire_or_store_2"
                          checked={
                            validation.values.buyer_retire_or_store === "Future"
                          }
                          onChange={() => true}
                          onClick={() =>
                            validation.setFieldValue(
                              "buyer_retire_or_store",
                              "Future"
                            )
                          }
                        />
                        <Label
                          className="form-check-label"
                          for="buyer_retire_or_store_2"
                        >
                          Future
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          // name="flexRadioDefault"
                          id="buyer_retire_or_store_3"
                          checked={
                            validation.values.buyer_retire_or_store ===
                            "Unknown"
                          }
                          onChange={() => true}
                          onClick={() =>
                            validation.setFieldValue(
                              "buyer_retire_or_store",
                              "Unknown"
                            )
                          }
                        />
                        <Label
                          className="form-check-label"
                          for="buyer_retire_or_store_3"
                        >
                          Unknown
                        </Label>
                      </div>
                    </div>

                    {validation.touched.buyer_retire_or_store &&
                    validation.errors.buyer_retire_or_store ? (
                      <FormFeedback type="invalid">
                        {validation.errors.buyer_retire_or_store}
                      </FormFeedback>
                    ) : null}
                  </Col>

                  <Col lg={4} className="mb-4">
                    <Label
                      for="buyer_vintage_cutoff-field"
                      className="form-label"
                    >
                      Earliest Vintage Accepted <i style={{ textTransform: 'none' }}>(by buyer)</i>
                    </Label>
                    <Input
                      name="buyer_vintage_cutoff"
                      id="buyer_vintage_cutoff-field"
                      className="form-control"
                      placeholder="YYYY"
                      type="number"
                      validate={{
                        required: {
                          value: true,
                        },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.buyer_vintage_cutoff || ""}
                      invalid={
                        validation.touched.buyer_vintage_cutoff &&
                        validation.errors.buyer_vintage_cutoff
                      }
                    />
                    {validation.touched.buyer_vintage_cutoff &&
                      validation.errors.buyer_vintage_cutoff && (
                        <FormFeedback type="invalid">
                          {validation.errors.buyer_vintage_cutoff}
                        </FormFeedback>
                      )}
                  </Col>

                  
                  <Col lg={4} className="mb-4">
                    <Label
                      for="buyer_consumerproduct-field"
                      className="form-label"
                    >
                      Will offsets integration into consumer product <i style={{ textTransform: 'none' }}>(incl. Point of Sale)</i>?
                    </Label>
                    <div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="buyer_consumerproduct_option_1"
                          value={"Yes"}
                          checked={
                            validation.values.buyer_consumerproduct === "Yes"
                          }
                          onChange={() => true}
                          onClick={(e) => {
                            validation.setFieldValue(
                              "buyer_consumerproduct",
                              "Yes"
                            );
                          }}
                        />
                        <Label
                          className="form-check-label"
                          for="buyer_consumerproduct_option_1"
                        >
                          Yes
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="buyer_consumerproduct_option_2"
                          value={"No"}
                          checked={
                            validation.values.buyer_consumerproduct === "No"
                          }
                          onChange={() => true}
                          onClick={(e) => {
                            validation.setFieldValue(
                              "buyer_consumerproduct",
                              "No"
                            );
                          }}
                        />
                        <Label
                          className="form-check-label"
                          for="buyer_consumerproduct_option_2"
                        >
                          No
                        </Label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Input
                          className="form-check-input"
                          type="radio"
                          id="buyer_consumerproduct_option_3"
                          value={"Unknown"}
                          checked={
                            validation.values.buyer_consumerproduct ===
                            "Unknown"
                          }
                          onChange={() => true}
                          onClick={(e) => {
                            validation.setFieldValue(
                              "buyer_consumerproduct",
                              "Unknown"
                            );
                          }}
                        />
                        <Label
                          className="form-check-label"
                          for="buyer_consumerproduct_option_3"
                        >
                          Unknown
                        </Label>
                      </div>
                    </div>
                    {validation.touched.buyer_consumerproduct &&
                      validation.errors.buyer_consumerproduct && (
                        <FormFeedback type="invalid">
                          {validation.errors.buyer_consumerproduct}
                        </FormFeedback>
                      )}
                  </Col>
                
              

              {/* <Col lg={4} className="mb-4">
                <Label for="buyer_qty_compliance" className="form-label">
                  What % of buyer's cap does this cover?
                </Label>
                <div className="input-group">
                  <Cleave
                    name="buyer_qty_compliance"
                    id="buyer_qty_compliance"
                    className="form-control"
                    options={{
                      numeral: true,
                    }}
                    value={validation.values.buyer_qty_compliance}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                  />
                  {validation.touched.buyer_qty_compliance &&
                  validation.errors.buyer_qty_compliance ? (
                    <FormFeedback type="invalid">
                      {validation.errors.buyer_qty_compliance}
                    </FormFeedback>
                  ) : null}
                  <span className="input-group-text">%</span>
                </div>
              </Col> */}

              {/*
              <Col lg={4}>
                <Label
                  for="buyer_retirement_policy-field"
                  className="form-label"
                >
                  buyer_retirement_policy
                </Label>
                <Input
                  name="buyer_retirement_policy"
                  id="buyer_retirement_policy-field"
                  className="form-control"
                  placeholder="buyer_retirement_policy"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.buyer_retirement_policy || ""}
                  invalid={
                    validation.touched.buyer_retirement_policy &&
                    validation.errors.buyer_retirement_policy
                      ? true
                      : false
                  }
                />
                {validation.touched.buyer_retirement_policy &&
                validation.errors.buyer_retirement_policy ? (
                  <FormFeedback type="invalid">
                    {validation.errors.buyer_retirement_policy}
                  </FormFeedback>
                ) : null}
              </Col> */}

              

              

              
            </Row>

            </>
            )}

            {/*<Row className="bg-soft-dark p-1 py-3 mb-2">*/}
              

              {/* <Col lg={12}>
                <Label for="projid_known-field" className="form-label">
                  projid_known
                </Label>
                <Input
                  name="projid_known"
                  id="projid_known-field"
                  className="form-control"
                  placeholder="projid_known"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.projid_known || ""}
                  invalid={
                    validation.touched.projid_known &&
                    validation.errors.projid_known
                  }
                />
                {validation.touched.projid_known &&
                validation.errors.projid_known ? (
                  <FormFeedback type="invalid">
                    {validation.errors.projid_known}
                  </FormFeedback>
                ) : null}
              </Col> */}
            {/*</Row>*/}
          </ModalBody>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="button"
                onClick={() => {
                  setModal(false);
                }}
                className="btn-light"
              >
                Close
              </Button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {!!isEdit ? "Update Trade" : "Add Trade"}
              </button>
              {/* <Button
                type="button"
                className="d-none"
                onClick={() => {
                  migrateTradeProject();
                }}
                >Run Migration</Button> */}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};


export default TradeForm;
