import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Project Redux States
import {
  GET_PROJECT_LIST,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  GET_TYPE_LIST, GET_PROJECT, GET_STANDARD_LIST, GET_COBENEFIT_LIST, GET_SDG_LIST, SET_PROJECT_QUERY_PARAMS
} from "./actionType";

import {
  ProjectApiResponseSuccess,
  ProjectApiResponseError,
  addProjectSuccess,
  addProjectFail,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
  setProjectApiLoading,
  setProjectQueryParams,
} from "./action";

//Include Both Helper File with needed methods
import {
  getProjectList as getProjectListApi,
  getProject as getProjectApi,
  getTypeList as getTypeListApi,
  getStandardList as getStandardListApi,
  getCoBenefitList as getCoBenefitListApi,
  getSDGList as getSDGListApi,
  addNewProject,
  updateProject,
  deleteProject,
} from "../../helpers/backend_helper";

function* handleSetProjectQueryParams(params) { 
  yield call(setProjectQueryParams, params.payload);
}

function* getProjectList(params) { 
  try {
    yield put(setProjectApiLoading(true));
    const response = yield call(getProjectListApi, params.payload);
    yield put(ProjectApiResponseSuccess(GET_PROJECT_LIST, response));
    yield put(setProjectApiLoading(false));
  } catch (error) {
    console.log(error);
    yield put(ProjectApiResponseError(GET_PROJECT_LIST, error));
  }
}

function* getProject(params) {
  try {
    yield put(ProjectApiResponseSuccess(GET_PROJECT, {}));
    const response = yield call(getProjectApi, params.payload);
    yield put(ProjectApiResponseSuccess(GET_PROJECT, response));
  } catch (error) {
    yield put(ProjectApiResponseError(GET_PROJECT, error));
  }
}

function* getTypeList(params) {
  try {
    const response = yield call(getTypeListApi, params.payload);
    yield put(ProjectApiResponseSuccess(GET_TYPE_LIST, response));
  } catch (error) {
    console.log(error);
    yield put(ProjectApiResponseError(GET_TYPE_LIST, error));
  }
}

function* getStandardList(params) {
  try {
    const response = yield call(getStandardListApi, params.payload);
    yield put(ProjectApiResponseSuccess(GET_STANDARD_LIST, response));
  } catch (error) {
    console.log(error);
    yield put(ProjectApiResponseError(GET_STANDARD_LIST, error));
  }
}

function* getCoBenefitList(params) {
  try {
    const response = yield call(getCoBenefitListApi, params.payload);
    yield put(ProjectApiResponseSuccess(GET_COBENEFIT_LIST, response));
  } catch (error) {
    console.log(error);
    yield put(ProjectApiResponseError(GET_COBENEFIT_LIST, error));
  }
}

function* getSDGList(params) {
  try {
    const response = yield call(getSDGListApi, params.payload);
    yield put(ProjectApiResponseSuccess(GET_SDG_LIST, response));
  } catch (error) {
    console.log(error);
    yield put(ProjectApiResponseError(GET_SDG_LIST, error));
  }
}

function* onAddNewProject({ payload: project }) { 
  try {
    const response = yield call(addNewProject, project);
    if( response.project_id ) {
      yield put(addProjectSuccess(response));
      toast.success("Project Added Successfully", { autoClose: 3000 });
    } else {
      yield put(addProjectFail(response));
      toast.error(response.error, { autoClose: 6000 });
    }
    
  } catch (error) {
    yield put(addProjectFail(error));
    toast.error("Project addition failed.  "+error.error, { autoClose: 3000 });
  }
}

function* onDeleteProject({ payload: project }) {
  try {
    const response = yield call(deleteProject, project);
    yield put(deleteProjectSuccess({ project }));
    toast.success("Project Delete Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(deleteProjectFail(error));
    toast.error("Project Delete Failed", { autoClose: 3000 });
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(updateProject, project);
    console.log(response);
    yield put(updateProjectSuccess(response));
    toast.success("Project Updated Successfully", { autoClose: 3000 });
  } catch (error) {
    console.log(error);
    yield put(updateProjectFail(error));
    toast.error("Project Updated Failed", { autoClose: 3000 });
  }
}

export function* watchSetProjectQueryParams() {
  yield takeEvery(SET_PROJECT_QUERY_PARAMS, handleSetProjectQueryParams);
}

export function* watchGetProjectList() {
  yield takeEvery(GET_PROJECT_LIST, getProjectList);
}

export function* watchGetProject() {
  yield takeEvery(GET_PROJECT, getProject);
}

export function* watchAddNewProject() {
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject);
}

export function* watchUpdateProject() {
  yield takeEvery(UPDATE_PROJECT, onUpdateProject);
}

export function* watchDeleteProject() {
  yield takeEvery(DELETE_PROJECT, onDeleteProject);
}

export function* watchGetTypeList() {
  yield takeEvery(GET_TYPE_LIST, getTypeList);
}

export function* watchGetStandardList() {
  yield takeEvery(GET_STANDARD_LIST, getStandardList);
}

export function* watchGetCoBenefitList() {
  yield takeEvery(GET_COBENEFIT_LIST, getCoBenefitList);
}

export function* watchGetSDGList() {
  yield takeEvery(GET_SDG_LIST, getSDGList);
}

function* projectSaga() {
  yield all([
    fork(watchGetProjectList),
    fork(watchGetProject),
    fork(watchAddNewProject),
    fork(watchUpdateProject),
    fork(watchDeleteProject),
    fork(watchGetTypeList),
    fork(watchGetStandardList),
    fork(watchGetCoBenefitList),
    fork(watchGetSDGList),
    fork(watchSetProjectQueryParams),
  ]);
}

export default projectSaga;
