import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  UncontrolledCollapse,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import { getProjectList, deleteProject, setProjectQueryParams } from '../../../store/actions';
import ProjectForm from '../../Project/components/ProjectForm';
import { api } from '../../../config';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/projects", title: "Public Project List Page" });

const ProjectList = () => {
  const dispatch = useDispatch();

  const projectsPerPage = 20;

  const {
    user,
    projects,
    projectLoading,
    projectListCount,
    error,
    isProjectSuccess,
    isProjectCreated,
    projectQueryParams,
    limit,
    offset,
    projectSearch,
    orderby,
    sort,
    page,
    exclude_noid,
    project_name,
    project_code,
    country_name,
    project_type,
    types,
    certs,
    sdgs,
  } = useSelector((state) => ({
    user: state.Login.user,
    projects: state.Project.projectList,
    projectLoading: state.Project.projectLoading,
    projectListCount: state.Project.projectListCount,
    error: state.Project.error,
    isProjectSuccess: state.Project.isProjectSuccess,
    isProjectCreated: state.Project.isProjectCreated,
    projectQueryParams: state.Project.projectQueryParams,
    limit: state.Project.projectQueryParams.limit,
    offset: state.Project.projectQueryParams.offset,
    projectSearch: state.Project.projectQueryParams.projectSearch,
    orderby: state.Project.projectQueryParams.orderby,
    sort: state.Project.projectQueryParams.sort,
    page: state.Project.projectQueryParams.page,
    exclude_noid: state.Project.projectQueryParams.exclude_noid,
    project_name: state.Project.projectQueryParams.project_name,
    project_code: state.Project.projectQueryParams.project_code,
    country_name: state.Project.projectQueryParams.country_name,
    project_type: state.Project.projectQueryParams.project_type,
    types: state.Project.projectQueryParams.types,
    certs: state.Project.projectQueryParams.certs,
    sdgs: state.Project.projectQueryParams.sdgs,
  }));

  //console.log('projectQueryParams', projectQueryParams);

  //let projectListInitializing = true;

  const [currentProject, setCurrentProject] = useState(null);

  const [projectName, setProjectName] = useState(project_name);
  const [projectId, setProjectId] = useState(project_code);
  const [projectCountry, setProjectCountry] = useState(country_name);
  const [projectType, setProjectType] = useState(project_type);

  const sdgList = [
    {
        "sdgcode": 1,
        "sdgname": "No Poverty",
        "colour": "#e5243b",
        "icon": null
    },
    {
        "sdgcode": 2,
        "sdgname": "Zero Hunger",
        "colour": "#dda63a",
        "icon": null
    },
    {
        "sdgcode": 3,
        "sdgname": "Good Health & Well-Being",
        "colour": "#4c9f38",
        "icon": null
    },
    {
        "sdgcode": 4,
        "sdgname": "Quality Education",
        "colour": "#c5192d",
        "icon": null
    },
    {
        "sdgcode": 5,
        "sdgname": "Gender Equality",
        "colour": "#ff3a21",
        "icon": null
    },
    {
        "sdgcode": 6,
        "sdgname": "Clean Water & Sanitation",
        "colour": "#26bde2",
        "icon": null
    },
    {
        "sdgcode": 7,
        "sdgname": "Affordable & Clean Energy",
        "colour": "#fcc30b",
        "icon": null
    },
    {
        "sdgcode": 8,
        "sdgname": "Decent Work & Economic Growth",
        "colour": "#a21942",
        "icon": null
    },
    {
        "sdgcode": 9,
        "sdgname": "Industry, Innovation & Infrastructure",
        "colour": "#fd6925",
        "icon": null
    },
    {
        "sdgcode": 10,
        "sdgname": "Reduced Inequality",
        "colour": "#fd6925",
        "icon": null
    },
    {
        "sdgcode": 11,
        "sdgname": "Sustainable Cities & Communities",
        "colour": "#fd9d24",
        "icon": null
    },
    {
        "sdgcode": 12,
        "sdgname": "Responsible Consumption & Production",
        "colour": "#bf8b2e",
        "icon": null
    },
    {
        "sdgcode": 13,
        "sdgname": "Climate Action",
        "colour": "#3f7e44",
        "icon": null
    },
    {
        "sdgcode": 14,
        "sdgname": "Life Below Water",
        "colour": "#0a97d9",
        "icon": null
    },
    {
        "sdgcode": 15,
        "sdgname": "Life on Land",
        "colour": "#56c02b",
        "icon": null
    },
    {
        "sdgcode": 16,
        "sdgname": "Peace And Justice Strong Institutions",
        "colour": "#00689d",
        "icon": null
    },
    {
        "sdgcode": 17,
        "sdgname": "Partnerships To Achieve The Goal",
        "colour": "#19486a",
        "icon": null
    }
];

  const paginationInput = useRef(null);


  const updateQueryParams = (params) => {
    let new_params = { ...projectQueryParams, ...params };
    dispatch(setProjectQueryParams(new_params));
  };

  const filterBySdgs = (value) => {
    value = value.replace('sdg', '');
    let filterVal = value;
    
    if( sdgs !== '' ){
      let sdgArray = sdgs.split(',');
      if (sdgArray?.some((sdg) => sdg === value)) {
        filterVal = sdgArray?.filter((sdg) => sdg !== value).join(',');
      } else {
        filterVal = [...sdgArray, value].join(',');
      } 
    }
    updateQueryParams({sdgs: filterVal, page: 1, offset: 0});
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if( projectName != project_name || projectId != project_code || projectCountry != country_name || projectType != project_type ){
        console.log('here');
        let params = { page: 1, offset: 0, project_name: projectName, project_code: projectId, country_name: projectCountry, project_type: projectType };
        updateQueryParams(params);
    }
    }, 300)
    return () => clearTimeout(delayDebounceFn)
  }, [projectName, projectId, projectCountry, projectType]);

  useEffect(() => {
    dispatch(getProjectList(projectQueryParams));
  }, [projectQueryParams]);


  

  const getPage = (new_page) => {
    if( Number.isInteger(parseInt(new_page)) && parseInt(new_page) > 0 ){
      if( paginationInput?.current?.value ){
        paginationInput.current.value = parseInt(new_page);
      }
      let new_offset = (new_page - 1)*projectsPerPage;
      dispatch(setProjectQueryParams({ ...projectQueryParams, page: new_page, offset: new_offset }));
    }
  };

  useEffect(() => {
    if( paginationInput?.current?.value )
    paginationInput.current.value = page;
  }, []);

  const clearFilter = () => {
    setProjectName('');
    setProjectId('');
    setProjectType('');
    setProjectCountry('');
    updateQueryParams({ page: 1, offset: 0, sdgs: '', project_name: '', project_code: '', country_name: '', project_type: '', sort: 'asc', orderby: 'project_code' });
  };

  // Project Form Modal
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrentProject(null);
    } else {
      setModal(true);
    }
  }, [modal]);



  const onClickDelete = (project) => {
    setCurrentProject(project);
    setDeleteModal(true);
  };

  const onClosed = () => {
    setCurrentProject(null);
  };

  const handleDeleteTask = () => {
    if (currentProject) {
      dispatch(deleteProject(currentProject));
      setDeleteModal(false);
      setCurrentProject(null);
    }
  };

  const handlePaginationInputChange = debounce( (input) => {
    let { value, max } = input;
    let inputPage = parseInt(value);
    max = parseInt(max);
    if( inputPage > max ){
      input.value = max;
      inputPage = max;
    } 
    if( Number.isInteger(inputPage) && inputPage > 0 ){
      getPage(inputPage);
    }
  }, 250);

  const setOrderAndSort = (order_by) => {
    if( order_by === orderby ){
      let newSortOrder = sort === 'asc' ? 'desc' : 'asc';
      updateQueryParams({ orderby: order_by, sort: newSortOrder});
    } else {
      updateQueryParams({ orderby: order_by, sort: 'asc'});
    }
  };


  document.title = 'Ecosystem Marketplace - Project List';
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => {
          setCurrentProject(null);
          setDeleteModal(false);
        }}
      />
      <ProjectForm
        isEdit={currentProject}
        modal={modal}
        setModal={setModal}
        currentProject={currentProject}
        toggle={toggle}
        onClosed={onClosed}
      />
      <div className='page-content'>
        
          
        <Container fluid={true}>
          <BreadCrumb title='Project List' pageTitle='Projects' />
          <Row><CardBody className="p-10 pt-0"  style={{maxWidth:'1385px'}}>On this page you can explore the details from over 20,000 carbon credit projects. Project registration, issuance, and retirement data from the largest carbon credit standards are regularly updated.</CardBody></Row>
          <Row>
            <Col xl={2} lg={3}>
              <Card>
                <CardHeader>
                  <div className='d-flex mb-3'>
                    <div className='flex-grow-1'>
                      <h5 className='fs-16'>Advanced Search</h5>
                    </div>
                    <div className='flex-shrink-0'>
                      <button
                        onClick={clearFilter}
                        className='bg-transparent border-0 text-decoration-underline'>
                        Clear All
                      </button>
                    </div>
                  </div>

                  <div className='search-box mb-2 d-block'>
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder={`Project Name`}
                      value={projectName}
                      onChange={ (e) => setProjectName(e.target.value) }
                    />
                  </div>
                  <div className='search-box mb-2 d-block'>
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder={`Project Registry ID`}
                      value={projectId}
                      onChange={(e) => setProjectId(e.target.value)}
                    />
                  </div>
                  <div className='search-box mb-2 d-block'>
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder={`Country`}
                      value={projectCountry}
                      onChange={(e) => setProjectCountry(e.target.value)}
                    />
                  </div>

                  

                  
                </CardHeader>


                {/* <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingFMC'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                      Forest Management Certifications
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingFMC'>
                    <div
                      id='flush-collapseFMC'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingFMC'>
                      <div className='accordion-body text-body pt-0'>
                    
                    
                    <ul className='list-unstyled mb-0 filter-list'>
                    {projectCerts?.map(
                        (cert) =>
                          cert?.value &&
                          cert?.label && (
                            <li
                              className='d-flex flex-column gap-2 filter-check mb-3'
                              key={`keycert${cert?.value}`}>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  onChange={(e) =>
                                    filterByCertifications(e.target.value)
                                  }
                                  value={cert?.value}
                                  checked={projectCert?.some(
                                    (certification) => parseInt(certification) === cert?.value
                                  )}
                                  id={'cert'+cert?.value}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor={cert?.label}>
                                  {cert?.label}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                    </ul>


                    </div>
                    </div>
                  </UncontrolledCollapse>
                </div> */}




                {/* <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrands'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Categories
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrands'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrands'>
                      <div className='accordion-body text-body pt-0'>
                        <ul className='list-unstyled mb-0 filter-list'>
                          {categoriesList?.map(
                            (category) =>
                              category?.categoryid &&
                              category?.categoryname && (
                                <li
                                  className='d-flex flex-column gap-2 filter-check mb-3'
                                  key={`seycategory${category?.categoryid}`}>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      onChange={(e) =>
                                        filterByCategories(e.target.value)
                                      }
                                      value={category?.categoryid}
                                      checked={cate?.some(
                                        (cat) => +cat === +category?.categoryid
                                      )}
                                      id={category?.categoryname}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={category?.categoryname}>
                                      {category?.categoryname}
                                    </label>
                                  </div>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div> */}

                {/* <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrandsStandards'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Standards
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrandsStandards'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrandsStandards'>
                      <div className='accordion-body text-body pt-0'>
                        <ul className='list-unstyled mb-0 filter-list'>
                          {projectStandards?.map(
                            (standards) =>
                              standards?.name &&
                              standards?.shortname && (
                                <li
                                  className='d-flex flex-column gap-2 filter-check mb-3'
                                  key={`seyStandards${standards?.shortname}`}>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      onChange={(e) =>
                                        filterByStandards(e.target.value)
                                      }
                                      value={standards?.name}
                                      checked={projectStandard?.some(
                                        (standard) =>
                                          standard === standards?.name
                                      )}
                                      id={standards?.shortname}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={standards?.shortname}>
                                      {standards?.name}
                                    </label>
                                  </div>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div> */}
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrandsSdgs'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        SDGS
                      </span>
                    </button>
                    
                  </h2>
                  {sdgs !== '' && (
                    <style dangerouslySetInnerHTML={{__html: `
                      #flush-headingBrandsSdgs:after {display:none}
                      #flush-headingBrandsSdgs {cursor:default}
                      `}} />  
                  )}
                  <UncontrolledCollapse toggler='#flush-headingBrandsSdgs' className={sdgs === '' ? '' : 'show'}>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrandsSdgs'>
                      <div className='accordion-body text-body pt-0'>
                        <ul className='list-unstyled mb-0 filter-list'>
                          {sdgList?.map(
                            (sdg) =>
                              sdg?.sdgcode &&
                              sdg?.sdgname && (
                                <li
                                  className='d-flex flex-column gap-2 filter-check mb-3'
                                  key={`seysdg${sdg?.sdgcode}`}>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      onChange={(e) =>
                                        filterBySdgs(e.target.id)
                                      }
                                      value={sdg?.sdgname}
                                      checked={sdgs.split(',')?.some(
                                        (cat) => parseInt(cat) === sdg?.sdgcode
                                      )}
                                      id={'sdg'+sdg?.sdgcode}
                                    />
                                    <label
                                      className='form-check-label '
                                      htmlFor={sdg?.sdgname}>
                                      <span
                                        style={{
                                          backgroundColor: sdg?.colour,
                                          marginRight: '2px',
                                        }}
                                        className='text-white badge'>
                                        {sdg?.sdgcode}
                                      </span>
                                      {sdg?.sdgname}
                                    </label>
                                  </div>
                                </li>
                              )
                          )}
                          
                        </ul>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
                {/* <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrandsAdvance'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Advanced
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrandsAdvance'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrandsAdvance'>
                      <div className='accordion-body text-body pt-0'>
                        <div className='d-flex flex-column gap-2 filter-check mb-3'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              onChange={handleCorsia}
                              value={corsia}
                              id='productCorsia'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='productCorsia'>
                              <span className='text-muted'>
                                <i className='mdi mdi-airplane-check text-success'></i>
                              </span>{' '}
                              CORSIA eligible
                            </label>
                          </div>
                        </div>
                        <h2 className='accordion-header'>
                          <button
                            className='border-0 p-0 button bg-transparent shadow-none mb-3'
                            type='button'>
                            <span className='text-muted text-uppercase fs-12 fw-medium'>
                              Cobenefits
                            </span>
                          </button>
                        </h2>
                        {cobenefits?.map(
                          (cobenefit) =>
                            cobenefit?.cobenefitid &&
                            cobenefit?.cobenefitname && (
                              <div
                                className='d-flex flex-column gap-2 filter-check mb-3'
                                key={`KeyCobenefit${cobenefit?.cobenefitid}`}>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    onChange={(e) =>
                                      filterCoBenefits(e.target.value)
                                    }
                                    value={cobenefit?.cobenefitname}
                                    checked={projectCoBenefits?.some(
                                      (cobenefits) =>
                                        cobenefits === cobenefit?.cobenefitname
                                    )}
                                    id={cobenefit?.cobenefitname}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={cobenefit?.cobenefitname}>
                                    {cobenefit?.cobenefitname}
                                  </label>
                                </div>
                              </div>
                            )
                        )}

                        <h2 className='accordion-header'>
                          <button
                            className='border-0 p-0 button bg-transparent shadow-none my-3'
                            type='button'>
                            <span className='text-muted text-uppercase fs-12 fw-medium'>
                              Certifications
                            </span>
                          </button>
                        </h2>
                        {certifications?.map(
                          (certification) =>
                            certification?.certificationid &&
                            certification?.certificationname && (
                              <div
                                className='d-flex flex-column gap-2 filter-check mb-3'
                                key={`Keycertification${certification?.certificationid}`}>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    onChange={(e) =>
                                      filterCertifications(e.target.value)
                                    }
                                    checked={projectCertification?.some(
                                      (certificate) =>
                                        certificate ===
                                        certification?.certificationname
                                    )}
                                    value={certification?.certificationname}
                                    id={certification?.certificationname}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={certification?.certificationname}>
                                    {certification?.certificationname}
                                  </label>
                                </div>
                              </div>
                            )
                        )}

                        <div className='filter-choices-input mb-3'>
                          <input
                            className='form-control'
                            type='text'
                            id='filter-choices-input'
                            value={projectMethdology}
                            onChange={(e) => filterMethdology(e?.target?.value)}
                            placeholder='Methdology'
                          />
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div> */}
              </Card>
            </Col>
            <Col xl={10} lg={9}>
              <Card>
                {/* <CardHeader>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Projects</h5>
                    <div className='flex-shrink-0'>
                      <button
                        type='button'
                        className='btn btn-success add-btn'
                        id='create-btn'
                        onClick={() => {
                          // setCurrentProject(null);
                          setModal(true);
                        }}>
                        <i className='ri-add-line align-bottom me-1'></i> Create
                        Project
                      </button>{' '}
                    </div>
                  </div>
                </CardHeader> */}
                {/* <CardBody className='border border-dashed border-end-0 border-start-0'>
                  <Row>
                    <Col sm={5}>
                      <div className='search-box me-2 mb-2 d-inline-block col-12'>
                        <input
                          id='search-bar-0'
                          type='text'
                          className='form-control search /'
                          placeholder={`Search...`}
                          onChange={debouncedResults}
                        />
                        <i className='bx bx-search-alt search-icon'></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody> */}
                <CardBody>
                  { projectLoading && <div className="loadingOverlay"> 
                      <div className="loadingSpinner"><Spinner /></div>
                    </div> }
                  <div className='table-responsive table-card mb-1'>
                    <table role="table" className="align-middle table-nowrap table table-hover" style={{tableLayout: 'fixed'}}>
                      <thead className="table-success text-muted">
                          <tr role="row">
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Project Registry ID <button onClick={() => {setOrderAndSort('project_code');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ orderby == 'project_code' && sort == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ orderby == 'project_code' && sort == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                <div><input id="top_project_code" className="form-control form-control-sm" placeholder="Project Registry ID" value={projectId}
                                  onChange={(e) => setProjectId(e.target.value)} /></div>
                            </th>
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}} >
                                Name <button onClick={() => {setOrderAndSort('project_name');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ orderby == 'project_name' && sort == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ orderby == 'project_name' && sort == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                <div><input id="top_project_name" className="form-control form-control-sm" placeholder="Name" value={projectName}
                                        onChange={ (e) => setProjectName(e.target.value) } /></div>
                            </th>
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Type <button onClick={() => {setOrderAndSort('typename');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ orderby == 'typename' && sort == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ orderby == 'typename' && sort == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                <div><input id="top_project_type" className="form-control form-control-sm" placeholder="Type" value={projectType} 
                                    onChange={(e) => setProjectType(e.target.value)} /></div>
                            </th>
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Country <button onClick={() => {setOrderAndSort('country_name');}} className="btn sort-button" style={{paddingLeft: 0, fontSize: '1.2rem', lineHeight: 1}}><span className="sort-arrows-container">{ orderby == 'country_name' && sort == 'asc' ? <i className="ri-arrow-up-s-fill arrow-solid"></i> : <i className="ri-arrow-up-s-fill"></i>}{ orderby == 'country_name' && sort == 'desc' ? <i className="ri-arrow-down-s-fill arrow-solid"></i> : <i className="ri-arrow-down-s-fill"></i>}</span></button>
                                <div><input id="top_project_country" className="form-control form-control-sm" placeholder="Country" value={projectCountry} 
                                    onChange={(e) => setProjectCountry(e.target.value)} /></div>
                            </th>
                            
                            {user?.is_superuser && (<th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>action</th>)}
                          </tr>
                      </thead>
                    
                  {isProjectSuccess && projects.length ? (
                    <tbody id="project_table_body" role="rowgroup">
                      {projects.map((project) => (
                        <tr key={project?.projectid}>
                          <td role="cell" style={{minWidth: '0px', width: '150px'}}><Link to={`/projectDetail/${project?.projectid}`} className='fw-medium link-primary'>{project?.project_code}</Link></td>
                          <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                            
                            <Link to={`/projectDetail/${project?.projectid}`} className='fw-medium link-primary'>{project?.project_name}</Link>
                            {/* <a className="fw-medium link-primary" href={"/project/" + project?.projectid}>{project?.project_name}</a> */}
                            
                          </td>
                          <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >{project?.typeid_name}</td>
                          <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >{project?.country_name}</td>
                          {user?.is_superuser && (
                          <td role="cell" style={{minWidth: '0px', width: '150px'}}>
                            <ul className='list-inline hstack gap-2 mb-0'>
                              <li className='list-inline-item edit'>
                               <Link
                                  to={`/project/edit/${project?.projectid}`}
                                  className='text-primary d-inline-block edit-item-btn'>
                                  <i className='ri-pencil-fill fs-16'></i>
                                </Link>
                              </li>
                              <li className='list-inline-item'>
                                <Link
                                  to='#'
                                  className='text-danger d-inline-block remove-item-btn'
                                  onClick={() => {
                                    onClickDelete(project);
                                  }}>
                                  <i className='ri-delete-bin-5-fill fs-16'></i>
                                </Link>
                              </li>
                            </ul>
                          </td>
                          )}
                        </tr>
                      ))}
                        

                        
                    </tbody>
                    
                    
                  ) : isProjectSuccess && !error ? (
                    <div className={projectLoading?'d-none':''} style={{padding:'20px'}}>No projects found</div>
                  ) : (
                    <Loader error={error} />
                  )}
                    </table>
                  </div>
                  {projectListCount ? (
                  <div className="justify-content-md-end justify-content-center align-items-center p-2 row">
                    <div className="col">Total Results : {projectListCount} </div>

                    {projectListCount > 20 && (
                    <>
                    <div className="col-md-auto col">
                      <div className="d-flex gap-1">
                        <button id="prev_page_btn" onClick={() => { getPage(page - 1); }} type="button" disabled={page == 1 && ('disabled')} className={page == 1 ? 'disabled btn btn-primary' : 'btn btn-primary'}>&lt;</button>
                      </div>
                    </div>
                    <div className="col-md-auto d-none d-md-block col">Page <strong>{page} of {Math.ceil(projectListCount/projectsPerPage)}</strong></div>
                    <div className="col-md-auto col">
                    <input ref={paginationInput} 
                        onChange={(e) => { 
                            handlePaginationInputChange(e.target); 
                        }}   
                        type="number" 
                        className="form-control" 
                        defaultValue={1} 
                        max={Math.ceil(projectListCount/projectsPerPage)} 
                        style={{width: '70px'}} 
                    />
                    </div>
                    <div className="col-md-auto col">
                      <div className="d-flex gap-1">
                        <button id="next_page_btn" onClick={() => { getPage(page + 1); }} type="button" disabled={page == Math.ceil(projectListCount/projectsPerPage) && ('disabled')} className={page == Math.ceil(projectListCount/projectsPerPage) ? "disabled btn btn-primary" : "btn btn-primary"}>&gt;</button>
                      </div>
                    </div>
                    </>
                  )}


                  </div>
                  ) : ''}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(ProjectList);
