import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Col,
  Card,
  CardBody, 
} from 'reactstrap'; 
 











const ListOfRespondentsPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
            <CardHeader className="align-items-center d-flex"><h2 className="mb-10 flex-grow-1">Respondents</h2></CardHeader>
                <CardBody>
                Ecosystem Marketplace thanks the following Respondents who contributed data on carbon credit transactions in 2023:
                    
                </CardBody>
                
                <Row>
                    <Col className="col-5 p-5 pt-2 respondent-list"><div>3Degrees</div><div>
ACCIONA</div><div>
Across Forest AS/Across Nature AS</div><div>
AGL</div><div>
Agrocortex</div><div>
Agroempresa Forestal</div><div>
Akre</div><div>
ALLCOT</div><div>
American Forest Foundation</div><div>
Anew Climate</div><div>
Appalachian Mountain Club</div><div>
Arbor Day</div><div>
Beijing Qianyuhui International Environmental Investment Co., Ltd. (QYH)</div><div>
BioCarbon Partners</div><div>
Biofílica Ambipar Environment</div><div>
BIOFIX</div><div>
BOCS Foundation</div><div>
Bonneville Environmental Foundation</div><div>
Bosques Amazonicos</div><div>
BrasilMataViva</div><div>
BVRio</div><div>
Caledonian Climate</div><div>
Canopée</div><div>
Carbone boréal (Université du Québec à Chicoutimi)</div><div>
Carbon Expert</div><div>
Carbonext</div><div>
Carbonfund.org</div><div>
Carbon Green Investments / Africa</div><div>
Carbon Offsets To Alleviate Poverty (COTAP)</div><div>
CarbonReset</div><div>
CarbonStore - Tillhill</div><div>
Carbon Tanzania</div><div>
CBL Markets (Xpansiv)</div><div>
CIMA</div><div>
Clean Air Action Corporation (TIST Program)</div><div>
Climate Bridge Ltd.</div><div>
ClimatePartner GmbH</div><div>
ClimeCo</div><div>
CO2CERO</div><div>
CO2Logic</div><div>
Conservation International</div><div>
Cool Effect</div><div>
Cooperativa AMBIO Programa Scolel'te</div><div>
C-Quest Capital</div><div>
Credible Carbon</div><div>
Eco2librium</div><div>
ECOEYE</div><div>
Econegocios</div><div>
Ecopart Assessoria em Negocios Empresariais Ltda. (EQAO)</div><div>
Ecosecurities</div><div>
Ecosphere+</div><div>
EFM Investment & Advisory</div><div>
EKI-EnergyServices</div><div>
Emergent Ventures</div><div>
ENGIE</div><div>
Enviro-Mark Solutions Ltd (trading as Toitū Envirocare)</div><div>
Everland</div><div>
FairClimateFund (formerly, Hivos Carbon Credits)</div><div>
Fondo Accion</div><div>
Forest Carbon (Indonesia)</div><div>
Forest Carbon Ltd (UK)</div><div>
FORLIANCE GmbH</div><div>
Fundação Carbon Offset Timor (FCOTI)</div>


 
</Col>
                    <Col className="col-5 p-5 pt-2 respondent-list">
                    <div>
Fundación para el Ecodesarrollo y la Conservación (FUNDAECO)</div><div>
Futuro Forestal</div><div>Global Forest Partners</div><div>
global-woods-international AG</div><div>
Gould International</div><div>
Greenoxx</div><div>
Green Resources</div><div>
Grupo Ecologico Sierra Gorda</div><div>
Highland Carbon</div><div>
Hivos (now, fairclimatefund)</div><div>
Infinite Solutions</div><div>
Inlandsis Fund</div><div>
Integrador de Comunidades Indígenas y Campesinas de Oaxaca AC</div><div> (ICICO)</div><div>
King County, Washington</div><div>
Land Carbon Ltd</div><div>
Life Climate and Energy Limited (Life Enerji)</div><div>
Livelihoods Venture</div><div>
Louis Dreyfus Company</div><div>
MEXICO2</div><div>
Microsol</div><div>
Mongolian Society for Rangeland Management</div><div>
Nature Conservancy Canada</div><div>
NCX (formerly, SilviaTerra)</div><div>
NedBank</div><div>
Nexus for Development</div><div>
Nordic Offset</div><div>
Nova Institute</div><div>
ONFInternational</div><div>
OstromClimate (formerly NatureBank)</div><div>
OurOffset</div><div>
Pachama</div><div>
PacificHydro</div><div>
PRIMAKLIMA</div><div>
Prosustentia</div><div>
Proyecto Mirador</div><div>
Quadriz</div><div>
Rabobank</div><div>
Radicle</div><div>
Redshaw Advisors Limited</div><div>
Respira International</div><div>
Rubicon Carbon</div><div>
Sanko Enerji</div><div>
Soluciones Proambientales</div><div>
South Pole</div><div>
STX Group (formerly Vertis-Strive)</div><div>
Sustainable Carbon</div><div>
Swiss Climate</div><div>
Taking Root</div><div>
Terra Global Capital</div><div>
The Climate Trust</div><div>
The Nakau Program</div><div>
The Nature Conservancy</div><div>
The Voluntary Climate Marketplace</div><div>
Timing Carbon Asset Management Co., Ltd.</div><div>
Trees for Life</div><div>
UPC Renewables</div><div>
UPM</div><div>
WayCarbon</div><div>
WeForest</div><div>
Wellington Management</div><div>
Worldview International Foundation</div><div>
ZeroMission</div>
</Col>
                </Row>
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        
        </React.Fragment>
    );
};

export default ListOfRespondentsPublic;
